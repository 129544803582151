import { ERoleId } from '~interfaces/Invitation';
import { EllipsisText, HorizontalDivider } from '~components/layout';
import { NavLink } from 'react-router-dom';
import {
    colorIllustrationLighterBlue,
    colorNeutralsWhite,
    colorOnWhiteDarkGrey,
    colorOnWhiteLightGrey,
    colorPrimaryMerBlue,
    font,
    screenWidthMedium,
    screenWidthSmall,
} from '~styles/variables';
import { useTranslation } from 'react-i18next';
import { useUser } from '~contexts/User';
import AppGenericLinks from '~components/navigation/AppGenericLinks';
import IUserRole from '~interfaces/UserRoles';
import React, { useMemo } from 'react';
import RoleLink from '~components/roles/RoleLink';
import styled from '@emotion/styled';
import useOrganization from '~hooks/useOrganization';

export enum EAccountNavigationVariant {
    NORMAL = 'normal',
    SIMPLE = 'simple',
}

type AccountNavigationProps = {
    toggleUserNavigation?: () => void;
    userEmail?: string;
    userName: string;
    variant?: EAccountNavigationVariant;
};

/*
LOGIC: Is supper oddly complex

  1. A resident role is not treated as selectable its only shown as an additional membership in the users network page
  2. If a user only has a single member role, we don't display any roles
  3. If a user only has a single family admin role, we don't display any roles
  4. If a user has admin roles we display them as selectable and show a selectable base user role
  4. If a user has admin roles and a family role, we show the admin roles as selectable and show the selectable family role in place of the base user role

 */

export default function AccountNavigation(props: AccountNavigationProps): JSX.Element {
    const { userName, userEmail, toggleUserNavigation, variant = EAccountNavigationVariant.NORMAL } = props;
    const { t } = useTranslation(['common']);
    const { roles, activeRole, changeRole, rolesCount, personalRole, alternateRole, additionalRoles, isRoleActive } =
        useUser();
    const { organization } = useOrganization({ organizationId: activeRole?.organizationId });

    const smbRole = useMemo(() => roles?.find((r) => r.roleId === ERoleId.SMB) ?? undefined, [roles]);

    const selectRole = (role: IUserRole) => {
        changeRole(role);
        toggleUserNavigation?.();
    };

    return (
        <Container className={variant}>
            <div>
                {variant === EAccountNavigationVariant.NORMAL ? (
                    smbRole ? (
                        <UserInfo>
                            <UserNameMenu>{organization?.name}</UserNameMenu>
                            {userEmail && <EllipsisText>{organization?.email}</EllipsisText>}
                        </UserInfo>
                    ) : (
                        <UserInfo>
                            <UserNameMenu>{userName}</UserNameMenu>
                            {userEmail && <EllipsisText>{userEmail}</EllipsisText>}
                        </UserInfo>
                    )
                ) : null}
                {personalRole && (alternateRole || additionalRoles?.length) ? (
                    <>
                        <StyledDivider />
                        <RoleLink
                            changeRole={() => selectRole(personalRole)}
                            role={personalRole}
                            variant={variant}
                            active={isRoleActive(personalRole)}
                        />
                    </>
                ) : null}
                {alternateRole ? (
                    <>
                        <RoleLink
                            changeRole={() => selectRole(alternateRole)}
                            role={alternateRole}
                            variant={variant}
                            active={isRoleActive(alternateRole)}
                        />
                    </>
                ) : null}
                {additionalRoles?.length ? (
                    <>
                        <HorizontalDivider />
                        <SelectRoleLink to={'/roles'}>
                            {t('userNavigation.links.roles', { count: rolesCount })}
                        </SelectRoleLink>
                    </>
                ) : null}
                <TextNavLinksContainer>
                    {variant === EAccountNavigationVariant.NORMAL ? <AppGenericLinks /> : null}
                </TextNavLinksContainer>
            </div>
        </Container>
    );
}

const Container = styled.nav`
    &.normal {
        position: fixed;

        box-shadow: 0 4px 4px rgba(33, 33, 33, 0.08);

        z-index: 500;
        background-color: ${colorNeutralsWhite};
        overflow: hidden;

        top: 4rem;
        right: calc(1rem - 2px);
        padding: 0 0 1rem 0;
        width: 22rem;
        height: auto;
        border: 1px solid ${colorOnWhiteDarkGrey};
        border-radius: 2px;
    }

    &.simple {
        width: 100%;
        overflow: hidden;
    }

    & .links {
        padding: 1rem 0 0 0;
        border-top: 1px solid ${colorOnWhiteLightGrey};
        list-style: none;
        text-align: right;

        a {
            padding: 1rem 1rem;
        }

        & .logout {
            margin-top: 1rem;
            padding-top: 2rem;
            border-top: 1px solid ${colorOnWhiteLightGrey};
        }
    }

    .desktop-only {
        display: none;
        visibility: hidden;
        @media screen and (min-width: ${screenWidthSmall}) {
            display: block;
            visibility: visible;
        }
    }
`;

const UserInfo = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 1.5rem 0 1rem;
    font-size: ${font.size.xs};
    line-height: ${font.lineHeight.m};

    @media screen and (min-width: ${screenWidthSmall}) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
`;

const UserNameMenu = styled.span`
    display: block;
    visibility: visible;
    font-family: 'SharpSans Bold', sans-serif;
    font-size: ${font.size.s};
`;

export const TextNavLinksContainer = styled.div`
    padding: 1rem 0 0 0;
    border-top: 1px solid ${colorOnWhiteLightGrey};
    list-style: none;
    text-align: right;

    a {
        padding: 1rem 1rem;
    }

    & .logout {
        margin-top: 1rem;
        padding-top: 2rem;
        border-top: 1px solid ${colorOnWhiteLightGrey};
    }

    a,
    a button {
        display: block;
        width: 100%;
        text-decoration: none;
        color: ${colorPrimaryMerBlue};
        text-align: left;
        font-family: 'SharpSans Semibold', sans-serif;

        &:hover {
            text-decoration: underline;
        }
    }

    > a {
        &:focus {
            button {
                background-color: ${colorIllustrationLighterBlue};
            }
        }
    }

    @media screen and (max-width: ${screenWidthMedium}) {
        a {
            padding-left: 1.5rem;
        }
    }
`;

const SelectRoleLink = styled(NavLink)`
    cursor: pointer;
    padding: 1rem;
    margin-bottom: 1rem;

    display: block;
    flex: 1;
    color: ${colorPrimaryMerBlue};
    text-align: left;
    font-family: 'SharpSans Semibold', sans-serif;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

const StyledDivider = styled(HorizontalDivider)`
    margin-bottom: 0;
`;
