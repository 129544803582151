import { ButtonSize, ButtonType, ButtonVariant } from '~@types';
import {
    colorIllustrationDarkBlue,
    colorIllustrationDarkerBlue,
    colorNeutralsWhite,
    colorOnWhiteDarkGrey,
    colorOnWhiteLightGrey,
    colorOnWhiteLighterGrey,
    colorPrimaryMerBlue,
    colorSecondaryRed,
    font,
    shadowBlueSharp,
    transitionSnappy,
} from '~styles/variables';
import React from 'react';
import styled from '@emotion/styled';

export type ButtonProps = {
    children: React.ReactNode;
    className?: string;
    destructive?: boolean;
    disabled?: boolean;
    isSubmitting?: boolean;
    onClick?: () => void;
    size?: ButtonSize;
    type?: ButtonType;
    variant?: ButtonVariant;
};

export default function Button({
    children,
    disabled,
    variant = ButtonVariant.PRIMARY,
    size = ButtonSize.DEFAULT,
    type = ButtonType.SUBMIT,
    destructive,
    onClick,
    className = '',
    isSubmitting,
}: ButtonProps): JSX.Element {
    return (
        <MerButton
            type={type}
            disabled={disabled}
            className={`btn--${variant} btn--size-${size} ${destructive ? 'btn--destructive' : ''} ${className} ${
                isSubmitting ? 'btn--submitting' : ''
            }`}
            onClick={onClick}
        >
            {children}
        </MerButton>
    );
}

const MerButton = styled.button`
    color: ${colorNeutralsWhite};
    background-color: ${colorPrimaryMerBlue};
    border: 0.0625rem solid ${colorPrimaryMerBlue};
    position: relative;

    font-family: 'SharpSans SemiBold', sans-serif;
    display: flex;
    justify-content: flex-start;
    gap: 0.75rem;
    align-items: center;

    font-size: ${font.size.m};
    padding: 0 1rem;
    height: 3rem;

    cursor: pointer;

    &.btn--size-${ButtonSize.SMALL} {
        font-size: ${font.size.s};
        padding: 0 0.75rem;
        height: 2rem;
    }

    outline: none;
    -webkit-tap-highlight-color: transparent;
    transition: all ${transitionSnappy};

    &:hover {
        background-color: ${colorIllustrationDarkBlue};
    }

    &:focus {
        background-color: ${colorIllustrationDarkBlue};
        box-shadow: ${shadowBlueSharp};
    }

    &:active {
        background-color: ${colorIllustrationDarkerBlue};
        box-shadow: none;
    }

    &:disabled {
        color: ${colorOnWhiteDarkGrey};
        background-color: ${colorOnWhiteLightGrey};
        border-color: ${colorOnWhiteLightGrey};
        cursor: not-allowed;
        box-shadow: none;
    }

    &.btn--${ButtonVariant.PRIMARY} {
        &.btn--destructive {
            background-color: ${colorSecondaryRed};
            border-color: transparent;
        }
    }

    &.btn--${ButtonVariant.SECONDARY} {
        color: ${colorPrimaryMerBlue};
        background-color: ${colorNeutralsWhite};
        border-color: ${colorPrimaryMerBlue};

        &.btn--destructive {
            border-color: ${colorSecondaryRed};
            color: ${colorSecondaryRed};
        }

        &:hover,
        &:focus {
            background-color: ${colorOnWhiteLighterGrey};
        }

        &:active {
            border-color: ${colorIllustrationDarkerBlue};
            color: ${colorIllustrationDarkerBlue};
            background-color: ${colorOnWhiteLighterGrey};
        }

        &:disabled {
            color: ${colorOnWhiteDarkGrey};
            background-color: ${colorOnWhiteLighterGrey};
            border-color: ${colorOnWhiteDarkGrey};
        }
    }

    &.btn--${ButtonVariant.TEXT} {
        color: ${colorPrimaryMerBlue};
        background-color: transparent;
        border-color: transparent;
        text-decoration: underline;

        &.btn--destructive {
            color: ${colorSecondaryRed};
        }

        &:hover {
            box-shadow: none;
            color: ${colorIllustrationDarkBlue};
            background-color: ${colorOnWhiteLighterGrey};
        }

        &:focus {
            background-color: ${colorOnWhiteLighterGrey};
            color: ${colorIllustrationDarkBlue};
        }

        &:active {
            background-color: ${colorOnWhiteLighterGrey};
            color: ${colorIllustrationDarkerBlue};
        }

        &:disabled {
            color: ${colorOnWhiteDarkGrey};

            &:hover {
                background-color: transparent;
            }
        }
    }

    &.btn--submitting::before {
        position: absolute;
        display: block;
        content: '';
        height: 5px;
        width: calc(100% + 2px);
        top: -1px;
        left: -1px;

        background: repeating-linear-gradient(to right, #42feff 0%, #0000c8 50%, #42feff 100%);
        background-size: 200% auto;
        background-position: 0 100%;
        animation: gradient 2s infinite;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
    }

    @keyframes gradient {
        0% {
            background-position: 0 0;
        }
        100% {
            background-position: -200% 0;
        }
    }

    & > svg {
        width: ${font.size.xl};
        height: ${font.size.xl};
        fill: currentColor;
    }
`;
