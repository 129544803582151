import {
    DEFAULT_ERROR_CODE,
    DEFAULT_ERROR_ERROR,
    DEFAULT_ERROR_MESSAGE,
    DEFAULT_ERROR_STATUS,
} from '~@constants/errors';
import {
    colorIllustrationLightBlue,
    colorNeutralsWhite,
    colorOnWhiteLighterGrey,
    colorPrimaryMerBlue,
    colorSecondaryRed,
} from '~styles/variables';
import { useTranslation } from 'react-i18next';
import ApiError from '~classes/ApiError';
import CloseIcon from '~assets/icons/close.svg';
import FormErrorIcon from '~assets/icons/form_error.svg';
import React, { useEffect, useRef, useState } from 'react';
import ValidationError from '~classes/ValidationError';
import styled from '@emotion/styled';

const Container = styled.div<{
    hasDismiss: boolean;
    hasLongWord?: boolean;
    severity: EErrorSeverity;
}>`
    display: flex;
    position: relative;

    justify-content: flex-start;
    padding: 0.5rem 0.5rem 0.5rem 0;
    margin-bottom: 2rem;
    align-items: center;
    font-size: 0.875rem;
    border-radius: 4px;
    border: 1px solid ${(props) => severityColors[props.severity]};
    background-color: ${colorOnWhiteLighterGrey};

    &:before {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        bottom: -1px;
        width: 3rem;
        z-index: 0;
        border-radius: 4px 0 0 4px;
        background-color: ${(props) => severityColors[props.severity]};
    }

    .icon {
        flex-grow: 0;
        padding: 0 0.5rem;
        align-self: center;
        font-size: 0;
        z-index: 2;

        & svg {
            fill: ${colorNeutralsWhite};
            width: 2rem;
            height: 2rem;
        }
    }

    button {
        margin-bottom: 0;
        color: ${(props) => severityColors[props.severity]};
        background: transparent;
        border: none;
        -webkit-appearance: none;
        font-family: 'SharpSans Medium', sans-serif;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            background-color: ${colorOnWhiteLighterGrey};
        }

        &:focus {
            outline: 4px solid ${colorIllustrationLightBlue};
        }
    }

    .message {
        display: inline-flex;
        align-items: center;
        padding-left: 0.5rem;
        flex-grow: 1;

        p {
            margin-bottom: 0;
            max-width: none;
            word-break: ${(props) => (props.hasLongWord ? 'normal' : 'break-word')};
        }
    }

    .buttons {
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
        justify-self: flex-end;
        flex-grow: 1;
        flex-shrink: 0;
        padding-left: 0.5rem;
    }
`;

const CloseButton = styled.button<{ severity: EErrorSeverity }>`
    margin-left: 0.5rem;
    width: 44px !important;
    height: 44px;
    font-size: 0;

    & svg {
        width: 2rem;
        fill: ${(props) => severityColors[props.severity]};
    }
`;

enum EErrorSeverity {
    NETWORK = 'network',
    SERVER = 'server',
    UNKNOWN = 'unknown',
    USER = 'user',
}

const severityColors = {
    [EErrorSeverity.USER]: colorSecondaryRed,
    [EErrorSeverity.SERVER]: colorSecondaryRed,
    [EErrorSeverity.NETWORK]: colorPrimaryMerBlue,
    [EErrorSeverity.UNKNOWN]: colorPrimaryMerBlue,
};

const severityStatus = {
    [EErrorSeverity.USER]: [400, 401, 409, 1000],
    [EErrorSeverity.SERVER]: [500, 501, 403, 404, 405],
    [EErrorSeverity.NETWORK]: [502, 503, 504],
    [EErrorSeverity.UNKNOWN]: [999],
};

// When re-doing the error codes maybe start mer errors at 1000+ that way there is no confusion with http status codes
// of course we also need to look at adyen payment error codes and auth0 error codes...

// TODO: Tim: map in translations to to error codes, e.g. error 400 is normally user input error, like invalid member number

// Added 403 and 404 as server error to be used with the RFID

const getSeverityFromStatus = (status: number) =>
    (Object.keys(severityStatus).find((k) => severityStatus[k as EErrorSeverity].includes(status)) as EErrorSeverity) ||
    EErrorSeverity.UNKNOWN;

export type FormErrorProps = {
    action?: () => void;
    actionLabel?: string;
    dismiss?: () => void;
    error: ApiError | ValidationError | Error;
    hasLongWords?: boolean;
    scrollTo?: boolean;
    translationOverride?: string;
    translationPath?: string;
    tryAgain?: () => void;
};

const defaultErrorPayload: ApiError['payload'] = {
    error: DEFAULT_ERROR_ERROR,
    message: DEFAULT_ERROR_MESSAGE,
    errorCode: DEFAULT_ERROR_CODE,
    status: DEFAULT_ERROR_STATUS,
    path: 'component.FormError',
    timestamp: new Date().getTime(),
};

export default function FormError(props: FormErrorProps): JSX.Element {
    const { t } = useTranslation(['common']);
    const { error, tryAgain, dismiss, action, actionLabel, translationPath, translationOverride, scrollTo } = props;
    const ref = useRef<HTMLDivElement>(null);
    const payload = error.hasOwnProperty('payload') ? (error as ApiError).payload : defaultErrorPayload;
    const errorCode = payload.additionalData?.refusalCode ?? payload?.errorCode;
    const [scrolled, setScrolled] = useState<boolean>(false);

    const translation =
        translationOverride ??
        t(
            [
                translationPath ? `${translationPath}.${errorCode}` : `errors.global.${errorCode}`,
                `errors.global.${errorCode}`, // always have the global fallback here in-case the translation path fails
            ],
            payload.message || error.message || payload.error || errorCode || DEFAULT_ERROR_MESSAGE,
        );

    const severity = getSeverityFromStatus(payload?.status || 999);

    useEffect(() => {
        if (scrollTo && ref.current && !scrolled) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
            setScrolled(true);
        }
    }, [scrollTo, scrolled]);

    return (
        <Container severity={severity} hasDismiss={!!dismiss} ref={ref}>
            <span className={'icon'}>
                <FormErrorIcon />
            </span>
            <div className={'message'}>
                <p>{translation}</p>
            </div>
            <div className={'buttons'}>
                {!!action && actionLabel ? (
                    <button type={'button'} className={'action'} onClick={action}>
                        {actionLabel}
                    </button>
                ) : null}
                {tryAgain ? (
                    <button type={'button'} className={'try-again'} onClick={tryAgain}>
                        {t('general.error.tryAgain')}
                    </button>
                ) : null}
                {dismiss ? (
                    <CloseButton
                        type={'button'}
                        className={'dismiss close-button'}
                        onClick={dismiss}
                        severity={severity}
                    >
                        <CloseIcon />
                    </CloseButton>
                ) : null}
            </div>
        </Container>
    );
}

// 500, 501 - server error, try again or contact support
// 502, 503, 504 - no response errors, try again soon
