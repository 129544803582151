import {
    colorNeutralsWhite,
    colorOnWhiteDarkGrey,
    colorOnWhiteLighterGrey,
    colorPrimaryMerBlue,
    font,
    screenWidthMini,
} from '~styles/variables';
import React from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';

export type AvatarProps = {
    active?: boolean;
    initials?: string;
};

export default function Avatar(props: AvatarProps) {
    const { initials, active } = props;
    return <AvatarContainer className={clsx({ active })}>{initials}</AvatarContainer>;
}

const AvatarContainer = styled.span`
    height: 2rem;
    width: 2rem;
    min-width: 2rem;
    line-height: 2rem;
    text-align: center;
    font-size: ${font.size.xs};
    font-weight: ${font.weight.bold};
    background-color: ${colorOnWhiteLighterGrey};
    color: ${colorOnWhiteDarkGrey};
    border-radius: 50%;
    flex: 0;

    text-decoration: none !important;

    &.active {
        color: ${colorNeutralsWhite};
        background-color: ${colorPrimaryMerBlue};
    }

    @media screen and (max-width: ${screenWidthMini}) {
        display: none;
    }
`;
