import { ActionsOutput } from 'little-state-machine/dist/types';
import { GlobalState, useStateMachine } from 'little-state-machine';

const setActiveRoleId = (globalState: GlobalState, payload: GlobalState['activeRoleId'] | undefined) => {
    return {
        ...globalState,
        activeRoleId: payload,
    };
};

const setIsRegistering = (globalState: GlobalState, payload: GlobalState['isRegistering']) => {
    return {
        ...globalState,
        isRegistering: payload,
    };
};

const setInvitationId = (globalState: GlobalState, payload: GlobalState['invitationId']) => {
    return {
        ...globalState,
        invitationId: payload,
    };
};

const setPayment = (globalState: GlobalState, payload: GlobalState['payment']) => {
    return {
        ...globalState,
        payment: payload,
    };
};

const setMembership = (globalState: GlobalState, payload: GlobalState['membership']) => {
    return {
        ...globalState,
        membership: payload,
    };
};

const setCoopMembershipResponse = (globalState: GlobalState, payload: GlobalState['coopMembershipResponse']) => {
    return {
        ...globalState,
        coopMembershipResponse: payload,
    };
};

const setCoopMembershipRedirect = (globalState: GlobalState, payload: GlobalState['coopMembershipRedirect']) => {
    return {
        ...globalState,
        coopMembershipRedirect: payload,
    };
};

const setCoopMembershipReturnUrl = (globalState: GlobalState, payload: GlobalState['coopMembershipReturnUrl']) => {
    return {
        ...globalState,
        coopMembershipReturnUrl: payload,
    };
};

const setCoopMembershipLogoutNextUrl = (
    globalState: GlobalState,
    payload: GlobalState['coopMembershipLogoutNextUrl'],
) => {
    return {
        ...globalState,
        coopMembershipLogoutNextUrl: payload,
    };
};

const setDismissedNotifications = (globalState: GlobalState, payload: GlobalState['dismissedNotifications']) => {
    return {
        ...globalState,
        dismissedNotifications: payload,
    };
};

const setRegistration = (globalState: GlobalState, payload: GlobalState['registration']) => {
    return {
        ...globalState,
        registration: payload,
    };
};

const setInvitation = (globalState: GlobalState, payload: GlobalState['invitation']) => {
    return {
        ...globalState,
        invitation: payload,
    };
};

const clearProductOrder = (globalState: GlobalState) => {
    return {
        ...globalState,
        productOrder: {},
    };
};

const storeProductOrder = (globalState: GlobalState, payload: GlobalState['productOrder'] | undefined) => {
    return {
        ...globalState,
        productOrder: {
            ...globalState.productOrder,
            ...payload,
        },
    };
};

const clearAftermarketOrder = (globalState: GlobalState) => {
    return {
        ...globalState,
        aftermarketOrder: {},
    };
};

const storeAftermarketOrder = (globalState: GlobalState, payload: GlobalState['aftermarketOrder'] | undefined) => {
    return {
        ...globalState,
        aftermarketOrder: {
            ...globalState.aftermarketOrder,
            ...payload,
        },
    };
};

const storeTempInvoices = (globalState: GlobalState, payload: GlobalState['tempInvoices']) => {
    return {
        ...globalState,
        tempInvoices: payload,
    };
};

const setEmailValidationReturnUrl = (globalState: GlobalState, payload: GlobalState['emailValidationReturnUrl']) => {
    return {
        ...globalState,
        emailValidationReturnUrl: payload,
    };
};

const setAftermarketProjectKey = (globalState: GlobalState, payload: string | undefined) => {
    return {
        ...globalState,
        aftermarketOrder: {
            ...globalState.aftermarketOrder,
            pk: payload,
        },
    };
};

const setUserRegistrationCompleteRoute = (globalState: GlobalState, payload: string | undefined) => {
    return {
        ...globalState,
        userRegistrationCompleteRoute: payload,
    };
};

const setLastAlternativeRoleId = (globalState: GlobalState, payload: string | undefined) => {
    return {
        ...globalState,
        lastAlternativeRoleId: payload,
    };
};

const clear = () => {
    return {
        order: {},
        payment: {},
        invoice: {},
        invoiceComplete: {},
        productOrder: {},
    };
};

type LocalStateAction<PayloadType = undefined> = (globalState: GlobalState, payload: PayloadType) => GlobalState;

type LocalStateActions = {
    clear: LocalStateAction;
    clearAftermarketOrder: LocalStateAction;
    clearProductOrder: LocalStateAction;
    setActiveRoleId: LocalStateAction<GlobalState['activeRoleId'] | undefined>;
    setAftermarketProjectKey: LocalStateAction<string | undefined>;
    setCoopMembershipLogoutNextUrl: LocalStateAction<GlobalState['coopMembershipLogoutNextUrl']>;
    setCoopMembershipRedirect: LocalStateAction<GlobalState['coopMembershipRedirect']>;
    setCoopMembershipResponse: LocalStateAction<GlobalState['coopMembershipResponse']>;
    setCoopMembershipReturnUrl: LocalStateAction<GlobalState['coopMembershipReturnUrl']>;
    setDismissedNotifications: LocalStateAction<GlobalState['dismissedNotifications']>;
    setEmailValidationReturnUrl: LocalStateAction<GlobalState['emailValidationReturnUrl']>;
    setInvitation: LocalStateAction<GlobalState['invitation']>;
    setInvitationId: LocalStateAction<GlobalState['invitationId']>;
    setIsRegistering: LocalStateAction<GlobalState['isRegistering']>;
    setLastAlternativeRoleId: LocalStateAction<GlobalState['lastAlternativeRoleId']>;
    setMembership: LocalStateAction<GlobalState['membership']>;
    setPayment: LocalStateAction<GlobalState['payment']>;
    setRegistration: LocalStateAction<GlobalState['registration']>;
    setUserRegistrationCompleteRoute: LocalStateAction<GlobalState['userRegistrationCompleteRoute']>;
    storeAftermarketOrder: LocalStateAction<GlobalState['aftermarketOrder'] | undefined>;
    storeProductOrder: LocalStateAction<GlobalState['productOrder'] | undefined>;
    storeTempInvoices: LocalStateAction<GlobalState['tempInvoices']>;
};

const useLocalState = (): {
    actions: ActionsOutput<LocalStateAction, LocalStateActions>;
    state: GlobalState;
} =>
    useStateMachine<LocalStateAction, LocalStateActions>({
        clear,
        clearAftermarketOrder,
        clearProductOrder,
        setActiveRoleId,
        setAftermarketProjectKey,
        setCoopMembershipLogoutNextUrl,
        setCoopMembershipRedirect,
        setCoopMembershipResponse,
        setCoopMembershipReturnUrl,
        setDismissedNotifications,
        setEmailValidationReturnUrl,
        setInvitation,
        setInvitationId,
        setIsRegistering,
        setLastAlternativeRoleId,
        setMembership,
        setPayment,
        setRegistration,
        setUserRegistrationCompleteRoute,
        storeAftermarketOrder,
        storeProductOrder,
        storeTempInvoices,
    });

export default useLocalState;
