import { ERoleConstraintOrigin, UserRoleConstraint } from '~interfaces/UserRoles';
import { useTranslation } from 'react-i18next';
import AlertComponent, { EAlertVariant } from '~components/forms/Alert';
import React from 'react';

export type RoleConstraintTranslationProperties = {
    constraintOrganizationClassification?: string;
    constraintOrganizationName?: string;
    constraintRoleName?: string;
};

type RoleConstraintWarningProps = {
    constraint: UserRoleConstraint;
    roleConstraintOrigin: ERoleConstraintOrigin;
    translationProperties?: RoleConstraintTranslationProperties;
};

export default function RoleConstraintWarning(props: RoleConstraintWarningProps): JSX.Element {
    const { constraint, roleConstraintOrigin, translationProperties } = props;
    const { t } = useTranslation(['common']);
    return (
        <AlertComponent variant={EAlertVariant.ALERT} margin={'0 0 1.5rem 0'}>
            {t(`roleConstraint.${constraint.roleConstraint}.warning.${roleConstraintOrigin}`, translationProperties)}
        </AlertComponent>
    );
}
