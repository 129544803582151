import { ETrackingEvent, ETrackingStep } from '~@types/tracking';
import { useCallback, useMemo } from 'react';
import ApiError from '~classes/ApiError';
import IInvitation, { EInvitationStatus, EInvitationType } from '~interfaces/Invitation';
import useAnalytics from '~hooks/useAnalytics';
import useApi, { ApiRequestOptions } from '~contexts/Api';
import useSWR from 'swr';

type UseInvitationProps = {
    invitationId?: string;
};

type UseInvitationResponse = {
    accept: (invitationId: string) => Promise<void>;
    decline: (invitationId: string) => Promise<void>;
    error?: ApiError;
    invitation: IInvitation | null;
    isExpired?: boolean;
    isValid?: boolean;
    loading?: boolean;
};

const API_OPTIONS = { isPublic: true };
const VALID_INVITATION_STATUSES = [EInvitationStatus.EMAIL_SENT, EInvitationStatus.CREATED];

export default function useInvitation(props: UseInvitationProps): UseInvitationResponse {
    const { get, patch, del } = useApi();
    const { invitationId } = props;
    const { trackGA4Event } = useAnalytics();

    const {
        error,
        data: invitation = null,
        isValidating: loading,
    } = useSWR<IInvitation, ApiError>(
        invitationId ? [`/invitations/${invitationId}`, API_OPTIONS] : null,
        ([url, options]: [string, ApiRequestOptions]) => get<IInvitation>(url, options),
        {
            revalidateOnFocus: false,
            errorRetryCount: 0,
        },
    );

    const accept: UseInvitationResponse['accept'] = useCallback(
        async (invitationId) => {
            await patch(`/invitations/${invitationId}`, {});
            if (invitation?.invitationType === EInvitationType.ACCOUNT_MIGRATION)
                trackGA4Event(
                    ETrackingEvent.ORGANIZATION_MIGRATION,
                    invitation && invitation.id === invitationId
                        ? { value: invitation.roleName, step: ETrackingStep.INVITATION_ACCEPT }
                        : { step: ETrackingStep.INVITATION_ACCEPT },
                );
            else
                trackGA4Event(
                    ETrackingEvent.INVITATION_ACCEPT,
                    invitation && invitation.id === invitationId ? { value: invitation.roleName } : undefined,
                );
        },
        [invitation, patch, trackGA4Event],
    );

    const decline: UseInvitationResponse['decline'] = useCallback(
        async (invitationId) => {
            await del(`/invitations/${invitationId}`);
        },
        [del],
    );

    const isValid = useMemo(
        () => (invitation?.status ? VALID_INVITATION_STATUSES.includes(invitation.status) : undefined),
        [invitation],
    );
    const isExpired = useMemo(
        () => (invitation?.status ? invitation?.status === EInvitationStatus.EXPIRED : undefined),
        [invitation],
    );

    return {
        loading,
        error,
        invitation,
        isValid,
        isExpired,
        accept,
        decline,
    };
}
