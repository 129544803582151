import { ERoleConstraintSolution } from '~interfaces/UserRoles';
import { useTranslation } from 'react-i18next';
import React from 'react';

const SOLUTION_URL: Record<ERoleConstraintSolution, string> = {
    [ERoleConstraintSolution.REMOVE_RESIDENT_ROLE]: 'mailto:support@mer.eco',
    [ERoleConstraintSolution.REMOVE_MEMBER_ROLE]: window.location.origin + '/account/network',
    [ERoleConstraintSolution.REMOVE_FAMILY_MEMBERS]: window.location.origin + '/account/network',
    [ERoleConstraintSolution.REMOVE_ADMIN_ROLE]: `mailto:${process.env.REACT_APP_CUSTOMER_SUPPORT_EMAIL ?? ''}`,
    [ERoleConstraintSolution.CONTACT_SUPPORT_ABOUT_ADMIN_ROLES]: 'mailto:support@mer.eco',
    [ERoleConstraintSolution.CONTACT_SUPPORT]: 'mailto:support@mer.eco',
};

type RoleConstraintSolutionProps = {
    invitationOrganizationName?: string;
    solution: ERoleConstraintSolution;
};

export default function RoleConstraintSolution(props: RoleConstraintSolutionProps): JSX.Element {
    const { solution, invitationOrganizationName } = props;
    const { t } = useTranslation(['common']);

    const solutionRoute = SOLUTION_URL[solution];

    return (
        <p>
            {t(`roleConstraintSolution.${solution}.prefix`, { invitationOrganizationName: invitationOrganizationName })}
            <a href={solutionRoute} target={'_blank'} rel={'noreferrer'}>
                {t(`roleConstraintSolution.${solution}.link`)}
            </a>
            {t(`roleConstraintSolution.${solution}.suffix`)}
        </p>
    );
}
