{
  "translationsFor": "Mer Portal Norwegian",
  "form": {
    "input": {
      "inviteeEmail": {
        "label": "Epostadresse",
        "invalid": "Du må legge inn en gyldig e-postadresse",
        "required": "E-post er påkrevd"
      },
      "inviteeName": {
        "required": "Mottakers navn er påkrevd",
        "invalid": "Ugyldig verdi"
      },
      "rfidSerialNumber": {
        "invalid": "Ugyldig brikkenummer. Vennligst sjekk at det er riktig.",
        "required": "Brikkenummer kreves",
        "label": "Brikkenummer"
      },
      "rfidDescription": {
        "label": "Beskrivelse",
        "invalid": "Ugyldig verdi",
        "required": "Beskrivelse kreves"
      },
      "rfidMember": {
        "label": "Registreres på"
      },
      "rfidActive": {
        "label": "Ladebrikken er aktiv"
      },
      "memberNumber": {
        "invalid": "Member number is invalid",
        "required": "Member number is required"
      },
      "memberTermsAgree": {
        "invalid": "You must agree to the terms to continue",
        "required": "You must agree to the terms to continue"
      },
      "memberSelect": {
        "label": "Medlem",
        "required": "Medlem kreves"
      },
      "useSameInformation": {
        "label": "Bruk samme adresse som i brukerprofilen min"
      },
      "firstName": {
        "label": "Fornavn",
        "invalid": "Ugyldig verdi",
        "required": "Fornavn kreves"
      },
      "lastName": {
        "label": "Etternavn",
        "required": "Etternavn kreves"
      },
      "privatePhone": {
        "label": "Telefonnummer (kan ikke endres)",
        "invalid": "Telefonnummer ikke gyldig",
        "required": "Telefonnummer kreves",
        "exists": "Telefonnummer finnes allerede"
      },
      "mobileNumber": {
        "label": "Mobilnummer",
        "labelReadOnly": "Mobilnummer (kan ikke endres)",
        "invalid": "Mobilnummer ikke gyldig",
        "required": "Mobilnummer kreves",
        "exists": "mobilnummeret finnes allerede"
      },
      "privatePhoneCreate": {
        "label": "Mobilnummer"
      },
      "businessPhone": {
        "label": {
          "readOnly": "Telefonnummer (kan ikke endres)",
          "notReadOnly": "Telefonnummer"
        },
        "invalid": "Telefonnummer ikke gyldig",
        "required": "Telefonnummer kreves"
      },
      "email": {
        "label": "E-postadresse",
        "invalid": "Ikke gyldig e-postadresse",
        "required": "E-postadresse kreves",
        "changePrefix": "endre i ",
        "change": "Kontoinnstillinger"
      },
      "organizationEmail": {
        "label": "E-postadresse (kontaktadresse)",
        "invalid": "Ikke gyldig e-postadresse",
        "required": "E-postadresse kreves",
        "change": "endre e-postadresse"
      },
      "emailNew": {
        "label": "E-post",
        "invalid": "Ikke gyldig e-postadresse",
        "required": "E-postadresse kreves"
      },
      "emailNewConfirm": {
        "label": "Gjenta E-post",
        "invalid": "E-postadresse er ulike",
        "required": "E-postadresse påkrevd"
      },
      "emailConfirm": {
        "label": "Gjenta E-post",
        "invalid": "E-postadresse er ulike",
        "required": "E-postadresse påkrevd"
      },
      "emailChange": {
        "match": "Du kan ikke bruke den eksisterende e-postadressen"
      },
      "addressLine1": {
        "label": "Postadresse (gateadresse eller postboks)",
        "labelOrganization": "Postadresse",
        "labelInvoice": "Postadresse (gateadresse eller postboks)",
        "invalid": "Ugyldig verdi",
        "required": "Postadresse kreves"
      },
      "postalCode": {
        "label": "Postnr.",
        "invalid": "Ugyldig verdi",
        "required": "Postnummer kreves"
      },
      "city": {
        "label": "Sted"
      },
      "country": {
        "label": "Land",
        "required": "Land kreves"
      },
      "useSameAddress": {
        "label": "Samme som i Kundeinformasjon"
      },
      "installationInstructions": {
        "label": "Beskrivelse av parkeringsanlegg",
        "help": "Eventuell informasjon om parkeringsanlegget som kan være viktig å vite for installasjonen"
      },
      "lastname": {
        "invalid": ""
      },
      "region": {
        "invalid": "Ugyldig verdi",
        "required": "Region kreves"
      },
      "role": {
        "label": "Medlemstype"
      },
      "vin": {
        "invalid": "VIN-nummer er ikke gyldig",
        "required": "VIN-nummer kreves"
      },
      "volvoOrder": {
        "invalid": "Volvo-bestillingsnummer er ugyldig",
        "required": "Volvo-bestillingsnummer kreves"
      },
      "vehicleLicencePlate": {
        "label": "Registreringsnummer",
        "required": "Registreringsnummer kreves",
        "invalid": "Ugyldig verdi"
      },
      "vehicleDescription": {
        "required": "Bilnavn kreves",
        "invalid": "Ugyldig verdi",
        "label": "Bilnavn"
      },
      "password": {
        "label": "Passord",
        "invalid": "Passordet må være minst 8 karakterer i lengde, inneholde både små (a-z) og store bokstaver (A-Z) i tillegg til nummer (0-9) eller spesialtegn (!@#$%^&*)."
      },
      "passwordConfirm": {
        "label": "Gjenta passord",
        "invalid": "Ugyldig passordbekreftelse. Passordene må være identiske."
      },
      "paymentCardNumber": {
        "label": "Kortnummer",
        "invalid": "Ugyldig verdi"
      },
      "paymentCardExpiry": {
        "label": "Utløpsdato",
        "invalid": "Utløpsdato må være i format MM/YY"
      },
      "paymentCardCvc": {
        "label": "CVV",
        "invalid": "CVV må ha format XXX(X)"
      },
      "invoiceReference": {
        "required": "Referanse kreves",
        "label": "Referanse",
        "invalid": "Ugyldig referanse"
      },
      "termsAccepted": {
        "required": "Du må godta betingelsene for å fortsette",
        "label": "Jeg har lest og godtar Mer sine ",
        "labelLink": "brukervilkår og betingelser"
      },
      "emailConsented": {
        "label": "Jeg samtykker til å motta tilbud og nyheter fra Mer på e-post"
      },
      "language": {
        "label": "Valgt språk"
      },
      "rfidChoice": {
        "required": "",
        "none": {
          "label": "Jeg trenger ikke ladebrikke"
        },
        "order": {
          "label": "Send meg en ladebrikke (kr {{price}},-)"
        },
        "existing": {
          "label": "Jeg har allerede en ladebrikke"
        }
      },
      "paymentTypeChoice": {
        "required": "",
        "card": {
          "label": "Betalingskort"
        },
        "eInvoice": {
          "label": "Epostfaktura ({{price}} kr per mnd)"
        },
        "ehf": {
          "label": "EHF",
          "help": "Faktureres månedlig"
        }
      },
      "organizationNumber": {
        "label": "Organisasjonsnummer",
        "invalid": "Ugyldig Organisasjonsnummer",
        "required": "Organisasjonsnummer kreves"
      },
      "organizationName": {
        "label": "Organisasjonsnavn",
        "invalid": "Ugyldig Organisasjonsnavn",
        "required": "Organisasjonsnavn kreves"
      },
      "companyPublic": {
        "label": "Offentlig organisasjon"
      }
    },
    "messages": {
      "input": {
        "required": "",
        "invalid": "",
        "unknown": ""
      }
    },
    "heading": {
      "contactInfo": "Kontaktinformasjon",
      "myVehicle": "Mitt kjøretøy",
      "charger": "Ladebrikker",
      "consent": "Samtykker",
      "choosePaymentType": "Velg betalingsmetode",
      "invoiceEmail": "Epostfaktura",
      "paymentEhf": "",
      "companyInfo": "Organisasjonsprofil",
      "companyContactInfo": "Kontaktinformasjon for organisasjonen",
      "paymentCard": "Betalingskort"
    },
    "description": {
      "charger": "Du kan starte lading med Mer-appen på mobil, eller bruke en ladebrikke du kan ha på nøkkelknippet.\n\nHvis du allerede har brikke fra Hyundai eller Elbilforeningen (sort brikke) kan du aktivere den ved å skrive inn nummeret på brikken i feltet under.",
      "choosePaymentType": "Velg betalingsmåte - og du er klar til å lade hos Mer!",
      "invoiceFee": "Faktureres månedlig",
      "paymentCard": "Lading trekkes fra betalingskortet umiddelbart."
    },
    "dateRange": {
      "from": "Fra",
      "to": "Til",
      "invalidDate": "Vennligst velg en gyldig dato eller periode",
      "dateAfterToday": "Dato kan ikke være senere enn dagens dato",
      "invalidRange": "Du har valgt en ugyldig periode",
      "tooManyDaysInRange": "Du kan ikke velge en periode lenger enn {{maxDays}} dager"
    }
  },
  "modal": {
    "addFamily": {
      "heading": "Legg til familiemedlem",
      "bodyConsent": "Sammen er vi Mer! Inviter opptil 4 medlemmer så dere raskere kan spare opp volumrabatt og få alt samlet på én faktura.",
      "bodyNoConsent": "Sammen er vi Mer! Inviter opptil 4 medlemmer så dere raskere kan spare opp volumrabatt og få alt samlet på én faktura.",
      "info": "Når de har akseptert koblingen eller opprettet bruker vil du bli belastet for deres lading på neste faktura.",
      "checkboxLabel": "Jeg samtykker til å betale for disse brukerne",
      "buttonState1": "Send invitasjon på e-post",
      "inviteeEmailLabel": "E-postadresse",
      "inviteeNameLabel": "Navn",
      "buttonAddAddresses": "Legg til flere e-postadresser",
      "buttonState2": "Send invitasjon",
      "buttonState2Plural": "Send invitasjoner"
    },
    "close": "Lukk",
    "addChip": {
      "heading": "Legg til brikke",
      "body": "Her kan du legge til brikker fra Mer og våre partnere.",
      "button": "Aktivér brikke"
    },
    "cancel": "Avbryt",
    "organizationEditRfid": {
      "heading": "Redigér brikke: ",
      "buttonMain": "Lagre endringer",
      "buttonSecondary": "Fjern brikke"
    },
    "vehicle": {
      "addHeading": "Nytt kjøretøy",
      "addButton": "Lagre nytt kjøretøy",
      "editHeading": "Endre kjøretøy",
      "editButton": "Lagre endringer",
      "removeHeading": "Slette \"{{description}}\"?",
      "removeInfo": "Er du sikker på at du vil slette dette kjøretøyet?",
      "removeButton": "Slett kjøretøy"
    },
    "changeEmail": {
      "heading": "Endre epostadresse",
      "body": "Oppgi den nye epostadressen du vil bruke for å logge inn i Mer Hub.",
      "email": "Ny epostadresse",
      "verifyOK": {
        "heading": "Endre epostadresse",
        "body": "Vi har sendt en e-post til den nye adressen. Åpne den og følg lenken.",
        "button": "Lukk"
      }
    },
    "verifyEmail": {
      "verifyFailed": {
        "heading": "Oops! Verifiseringen av mailen din mislyktes",
        "body1": "Vi lyktes ikke med å endre mailen din på grunn av.... ",
        "body2": "Vennligst forsøk en gang til.",
        "button": "Ok"
      },
      "changeFailed": {
        "heading": "Oops! Vi lyktes ikke med å endre mailen din",
        "body1": "Vi kunne ikke endre mailen din til....  ",
        "body2": "Vennligst forsøk igjen ved å klikke på denne ",
        "suportLink": "linken.",
        "button": "Ok"
      },
      "verifyOK": {
        "heading": "Ny epostadresse bekreftet",
        "body1": "Gratulerer!",
        "body2": "Du har nå endret epostadressen din til ",
        "bodyLogin": "Du kan nå logge inn på nytt for å få tilgang til Mer Hub.",
        "buttonLogin": "Gå til innlogging",
        "bodySetPassword": "Du må angi passordet ditt og deretter logge på for å fullføre endringen.",
        "buttonSetPassword": "Fortsett å angi passord",
        "button": "Ok"
      },
      "loadingScrollText": [
        "Oppdatering av e-postadresse",
        "Oppdatering kan ta omtrent 30 sekunder til 1 minutt."
      ]
    },
    "editChip": {
      "heading": "RFID",
      "body": "Privat ladebrikke for",
      "buttonMain": "Lagre endringer",
      "buttonSecondary": "Fjern denne ladebrikken fra min konto"
    },
    "orderChip": {
      "heading": "Bestill ny ladebrikke",
      "linkTitle": "Gå til profilsiden for å endre personlig informasjon. Du går ut av denne flyten.",
      "link": "Endre personlig informasjon",
      "dropdownLabel": "Antall brikker",
      "info": "Prisen pr brikke er {{price}}, du vil bli belastet {{total}} på din neste faktura.",
      "button": "Bestill nå"
    },
    "removeFamily": {
      "heading": "Fjern familiemedlem",
      "body": "Er du sikker på at du ønsker å fjerne koblingen til {{name}}?",
      "info": "Ladekostnadene for {{name}} blir belastet deg på neste faktura.",
      "button": "Fjern kobling"
    },
    "removeMember": {
      "heading": "Fjern medlem",
      "body": "Er du sikker på at du ønsker å fjerne koblingen til {{name}}?",
      "info": "Ladekostnadene for {{name}} blir belastet deg på neste faktura.",
      "button": "Fjern kobling"
    },
    "removeOwnMemberRole": {
      "heading": "Slette tilknytning?",
      "body": "Er du sikker på at du ønsker å slette tilkoblingen din til nettverket {{network}} sameie?",
      "infoBilling": "Du har ladet for {{amount}} denne måneden - dette vil bli belastet {{network}} sameie neste måned.",
      "infoPaymentMethod": "Du bør verifisere at du har en gyldig betalingsmetode, slik at du fortsatt kan lade og få kundefordeler.",
      "button": "Slett tilknytning"
    },
    "memberDisconnected": {
      "heading": "Din kobling til {{network}} har blitt slettet",
      "body": "Du bør verifisere at du har en gyldig betalingsmetode, slik at du fortsatt kan lade og få kundefordeler.",
      "button": "Kontroller betalingsmetode"
    },
    "residentDisconnected": {
      "heading": "Din kobling til {{network}} har blitt slettet",
      "body": "Fra nå av vil kontoen din bli satt som en privat konto. Du kan fortsette å lade og motta kundefordeler!",
      "button": "Legg til betalingsmåte"
    },
    "deleteAccount": {
      "heading": "Slette kontoen?",
      "body": "Er du sikker på at du vil slette din bruker hos oss? Ved å slette denne brukeren, vil du ikke lenger ha mulighet til å lade med appen - du vil også miste tilgang til dine kundefordeler og ladehistorikk.",
      "info": "Din konto vil forbli aktiv til slutten av inneværende måned og til sluttfaktura er betalt. Vennligst påse at du har gyldig betalingsmetode for å forhindre forsinkelser.",
      "button": "Slett konto"
    },
    "changePayment": {
      "heading": "Bytt betalingsmetode",
      "loading": "Oppdaterer betalingsmetode...",
      "eInvoice": {
        "instructions": "Faktureres månedlig."
      },
      "card": {
        "instructions": "Lading trekkes fra betalingskortet umiddelbart."
      },
      "button": {
        "submit": "Lagre ny betalingsmetode",
        "cancel": "Avbryt"
      }
    },
    "organizationChangePayment": {
      "heading": "Endre betalingsmetode",
      "loading": "Oppdaterer betalingsmetode...",
      "ehf": {
        "instructions": "Faktureres månedlig"
      },
      "eInvoice": {
        "instructions": "Faktureres månedlig"
      },
      "card": {
        "instructions": "Ladingen trekkes månedlig."
      },
      "button": {
        "submit": "Lagre ny betalingsmetode",
        "cancel": "Avbryt"
      }
    }
  },
  "general": {
    "network": "nettverk",
    "subscribe": "Abonnér",
    "subscription": "Abonnement",
    "subscriptions": "Abonnementer",
    "orderSubscription": "Bestill abonnement",
    "numberOf": "Antall",
    "shortMonth": "mnd",
    "kwh": "kWh",
    "currency": "kr",
    "minutes": "minutter",
    "shortMinutes": "min",
    "hours": "hours",
    "kronerPrefix": "Kr ",
    "shortHours": "h",
    "error": {
      "networkRequestError": "En feil oppstod",
      "networkRequestFailed": "En feil i forespørselen oppstod",
      "networkRequestNoResponse": "En feil i forespørselen oppstod",
      "fetchingData": "",
      "tryAgain": "Prøv igjen",
      "noSubscriptionsFound": "Fant ingen abonnementer",
      "membershipAlreadyExists": "Dette medlemskapet finnes allerede",
      "invalidMembershipId": "Ugyldig medlemsnummer"
    },
    "busy": {
      "fetchingData": ""
    },
    "edit": "Endre",
    "reactivate": "Reaktiver",
    "carousel": {
      "next": "Neste",
      "prev": "Forrige",
      "selected": "Valgt",
      "itemNumber": "Bilde nummer"
    },
    "learnMore": "Lær mer",
    "zeroPrice": "0,-",
    "includedInPrice": "Inkludert",
    "loadingProfile": "Laster data...",
    "redirectingToLogin": "Går til innloggingssiden...",
    "rfidProviders": "Du kan legge til brikker fra {{providers}}.",
    "paymentType": {
      "CARD": "Betalingskort",
      "E_INVOICE": "Epostfaktura",
      "EHF": "EHF",
      "KLARNA": "Klarna",
      "POSTAL": "Postal",
      "card": "Betalingskort",
      "E-invoicing": "Epostfaktura",
      "klarna": "Klarna",
      "Postal": "Postal"
    },
    "chargingAccessCategory": {
      "private": "Privat",
      "public": "Offentlig",
      "business": "Offentlig",
      "totalEnergy": "Totalt forbruk"
    },
    "excludingVat": "ekskludert mva",
    "includingVat": "inkludert mva",
    "vatReference": "Beløp {{vatStatus}}."
  },
  "userNavigation": {
    "rolePersonal": "Personlig",
    "roles": {
      "COMPANY": {
        "821200000": "",
        "821200001": "Medlem - {{network}}",
        "821200002": "Administrator",
        "821200003": "",
        "000000001": "Organisasjon"
      },
      "COOPERATIVE": {
        "821200000": "",
        "821200001": "Ansatt - {{network}}",
        "821200002": "Administrator",
        "821200003": "",
        "000000001": "Organisasjon"
      },
      "FAMILY": {
        "821200000": "",
        "821200001": "Nettverksmedlem",
        "821200002": "Nettverksadministrator",
        "821200003": "",
        "000000001": "Organisasjon"
      },
      "MANUFACTURER_VENDOR": {
        "821200000": "",
        "821200001": "",
        "821200002": "",
        "821200003": "",
        "000000001": "Organisasjon"
      },
      "PUBLIC": {
        "821200000": "",
        "821200001": "Medlem - {{network}}",
        "821200002": "Administrator",
        "821200003": "",
        "000000001": "Organisasjon"
      },
      "PRIVATE": {
        "821200000": "",
        "821200001": "",
        "821200002": "",
        "821200003": "",
        "000000001": "Organisasjon"
      },
      "SERVICE_PARTNER": {
        "821200000": "",
        "821200001": "",
        "821200002": "",
        "821200003": "",
        "000000001": "Organisasjon"
      }
    },
    "links": {
      "userProfile": "Min brukerprofil",
      "organizationProfile": "Organisasjonsprofil",
      "faq": "FAQ",
      "support": "Support",
      "roles": "Alle roller ({{count}})"
    },
    "closeMenu": "Lukk meny",
    "logOut": "Logg ut",
    "more": "Mer",
    "toggleMenu": "Åpne/lukke meny",
    "select": {
      "searchPlaceholder": "Finn role"
    },
    "roleSelectList": {
      "heading": "Hvilken rolle vil du bruke?",
      "instructions": "Du kan når som helst endre roller fra brukermenyen.",
      "continue": "Fortsette"
    }
  },
  "status": {
    "ACTIVE": "Aktiv",
    "active": "Aktiv",
    "INACTIVE": "Ikke aktiv",
    "inactive": "Ikke aktiv"
  },
  "page": {
    "myBenefits": {
      "coupons": {
        "addDiscount": {
          "heading": "Rabattkoder",
          "body": "Du kan legge til dine rabattkoder i feltet under",
          "buttonText": "Aktiver rabattkode"
        },
        "coop": {
          "blueBody": "Ved å aktivere ditt Coop Medlemsskap hos Mer, kan du få opptil 10% rabatt utbetalt som medlemsbonus hos Coop - hver gang du lader på en av Mer sine offentlige ladestasjoner!",
          "link": "Du kan bli medlem på coop.no",
          "note": "Du finner nummeret på medlemskortet ditt (siste 10 siffer) i Coop-appen, eller på Min Side på coop.no.",
          "orderNow": "Fullfør aktiveringen",
          "cancel": "Avbryt",
          "close": "Lukk vindu",
          "cancelBonus": "Avslutt Ladebonus",
          "heading": "COOP Ladebonus",
          "subHeading": "Coop medlemsbonus",
          "memberBody": "10% ladebonus på Coop-lokasjoner\n\n5% ladebonus på alle andre offentlige ladere",
          "memberInfo": "Takk for at du benytter deg av medlemsbonusen. Du kan se mer informasjon om din bonussparing hos Coop.",
          "bonusInfo": "Du har tjent {{bonus}} i bonus i {{month}}.",
          "bonusLinkPrefix": "Informasjon om din oppsparte bonus kan hentes fra ",
          "bonusLink": "Coop kundeportal",
          "bonusLinkUrl": "https://minside.coop.no/min-side/fordelsregnskap",
          "bonusLinkSuffix": ".",
          "nothing": "10% ladebonus på Coop-lokasjoner\n5% ladebonus på alle andre offentlige ladere\nDu har tjent 55kr i bonus i Februar.\nTakk for at du benytter deg av medlemsbonusen. Du kan se mer informasjon om din bonussparing hos Coop. ",
          "headingDisconnect": "COOP Ladebonus",
          "bonusInfoDisconnect": "Du har spart opp {{bonus}} hittil i {{month}} med Ladebonus.",
          "messageDisconnect": "Takk for at du benytter deg av ladebonusen. Du kan se mer informasjon om din bonussparing hos Coop.",
          "disconnect": "Deaktivér ladebonus",
          "buttonTextActive": "Deaktiver Ladebonus",
          "body": "10% ladebonus på Coop-lokasjoner\n\n5% ladebonus på alle andre offentlige ladere\n\nSom medlem i Coop får du 8 % Ladebonus hver gang du lader din elbil på våre ladestasjoner!",
          "buttonText": "Aktivér Ladebonus",
          "memberNumber": "Ditt medlemsnummer:",
          "notAMemberActivate": "Aktiver Ladebonus",
          "notAMemberHeading": "Er du Coop-medlem?",
          "notAMemberHeadingCompact": "COOP Ladebonus",
          "notAMemberBody": "10% ladebonus på Coop-lokasjoner\n\n5% ladebonus på alle andre offentlige ladere\n\nSom medlem i Coop får du 8 % Ladebonus hver gang du lader din elbil på våre ladestasjoner!",
          "familyMemberMessage": "Du er medlem av {{network}}, ladebonusen din går til administratoren av dette familienettverket",
          "familyAdminMembershipConnected": "Ditt COOP medlemsskap er registrert.",
          "membershipConnected": "Ditt COOP medlemsskap er registrert.",
          "promotion": {
            "heading": "Sommerkampanje",
            "subHeading": "16% hele Juli",
            "notAMemberActivate": "Aktiver Ladebonus",
            "notAMemberHeading": "Kampanje!",
            "notAMemberHeadingCompact": "16% Ladebonus",
            "notAMemberSubHeading": "Få 16 % bonus på lading*",
            "notAMemberBody": "Nå får Coop-medlemmer dobbel bonus på lading hos Mer i hele juli!",
            "notAMemberBodyNote": "* Ordinær bonus utenfor kampanjen er opptil 10%"
          }
        },
        "couponAmount": {
          "validUntil": "Gyldig til",
          "stackHeading": "Totalt for dine aktive kuponger",
          "heading": "Har du en rabattkode?",
          "body": "Beløpet kan benyttes i hele Mer sitt offentlige ladenettverk.",
          "stackSubHeading": "Aktive kuponger:",
          "leftOf": "igjen av",
          "period": "Faktureringsperiode:",
          "addNewCode": "Legg til ny kode"
        },
        "discount": {
          "volvo": {
            "name": "",
            "description": "Ladeunivers rabatt"
          },
          "osloTaxi": {
            "name": "Oslo Taxi-avtale",
            "description": "Du har tilgang på 25% rabatt formiddag + natt og 20% resten av døgnet. Dette gjelder alle Mers offentlig tilgjengelige ladestasjoner."
          },
          "norgesTaxi": {
            "name": "Norges taxiforbund-avtale",
            "description": "Du har tilgang på 25% rabatt formiddag + natt og 20% resten av døgnet. Dette gjelder alle Mers offentlig tilgjengelige ladestasjoner."
          },
          "rebateCampaign": {
            "name": "Rabattkampanje",
            "description": "Du har tilgang på 25% rabatt formiddag + natt og 20% resten av døgnet. Dette gjelder alle Mers offentlig tilgjengelige ladestasjoner."
          },
          "other": {
            "name": "",
            "description": "Ladeunivers rabatt"
          },
          "validUntil": "Gyldig til",
          "description": "Ladeunivers rabatt"
        },
        "Volvo": {
          "body": "Du får denne fordelen i tillegg til dine andre kuponger når du lader i vårt ladenett."
        },
        "freeCharge": {
          "freeChargeMonthly": "Gratis lading hver måned",
          "freeCharge": "Gratis lading",
          "monthlyBody": "Du som eier egen lader får 15 minutter gratis lading hver måned. Du kan benytte disse minuttene på hvilken som helst lader i vårt ladenett.",
          "oneTimeBody": "Du som eier din egen lader får 60 minutter kostnadsfri lading.",
          "bodyPrefix": "Du som eier egen lader får",
          "bodySuffix": "minutter kostnadsfri lading."
        },
        "freeKWH": {
          "subheading": "Gratis lading",
          "bodyPrefix": "Du som eier egen lader får",
          "bodySuffix": "kWh kostnadsfri lading."
        },
        "volumeRebate": {
          "header": "{{prefix}} {{discount}}% volumrabatt på våre offentlige ladere!",
          "defaultInstructions": "Lad for {{target}}kr i løpet av de neste {{daysLeftInMonth}} dagene for å få {{discount}}% rabatt.",
          "topTierInstructions": "Lad for {{target}}kr neste måned for å beholde rabatten."
        }
      },
      "heading": "Mine Fordeler",
      "headingFamilyAdmin": "Dine Fordeler"
    },
    "chargingJourney": {
      "download": {
        "pdf": "PDF",
        "excel": "Excel",
        "noValue": "Ingen data tilgjengelig"
      },
      "chargeDetailRecords": {
        "price": "Pris",
        "priceAfterDiscount": "Pris etter rabatt",
        "total": "Pris før rabatt",
        "saved": "Totalt spart",
        "type": "Type",
        "effect": "kWh",
        "avgEffect": "Snitteffekt",
        "parkingLot": "Park. plass",
        "heading": "",
        "location": "Lokasjon",
        "date": "Dato",
        "chargingTime": "Ladetid",
        "paid": "Betalt",
        "more": "Vis flere",
        "unknownRfid": "Ukjent brikke",
        "accessCategories": {
          "HOME": "Privat",
          "PUBLIC": "Offentlig"
        },
        "medium": {
          "APP": "App",
          "RFID": "RFID",
          "HOME": "Hjemme",
          "AUTOCHARGE": "Autocharge"
        }
      },
      "chargeDetailsGraph": {
        "heading": "",
        "noData": {
          "title": "Du har ikke ladet hos Mer i denne perioden",
          "message": "Når du lader får du statistikk og oversikt her"
        }
      },
      "heading": "Min ladereise",
      "headingFamilyAdmin": "Din ladereise",
      "subHeading": "Ladeøkter i perioden",
      "downloadButtonText": "Last ned",
      "showVATInfo": "Alle beløp vises {{vatStatus}}",
      "datePeriod": {
        "title": "Vis for",
        "month": "Én måned",
        "year": "Ett år",
        "all": "Alle år",
        "custom": "Dates"
      }
    },
    "facilityManagement": {
      "properties": {
        "overview": "Oversikt lokasjoner",
        "model": "Ladeboks",
        "available": "Tilgjengelighet",
        "status": "Status",
        "statusInfo": {
          "heading": "Status på en ladeboks kan være aktiv eller ikke aktiv.",
          "text": "Statusen aktiv betyr at laderen er tilknyttet en eier med et kundeabonnement. Statusen ikke aktiv betyr at lader ikke er tilknyttet en eier eller ladeabonnement."
        }
      },
      "error": "Enkelte av ladeboksene er registrert som “ikke aktive”, vennligst se mer på «ladernivå",
      "availableState": {
        "SMS": "Sms",
        "ORGANIZATION": "Privat",
        "PUBLIC": "Offentlig"
      },
      "kpi": {
        "totalTitle": "Installert lader",
        "totalInfo": "Total",
        "activeTitle": "Aktiv lader",
        "activeInfoPre": "Aktiv",
        "activeInfoPost": "Alle",
        "inActiveTitle": "Ikke aktiv lader",
        "inActiveInfoPre": "Ikke aktiv",
        "inActiveInfoPost": "Alle",
        "publicTitle": "Offentlig tilgjengelig",
        "publicInfoPre": "Ikke aktiv",
        "publicInfoPost": "Aktiv",
        "privateTitle": "Privat lader",
        "privateInfoPre": "Ikke aktiv",
        "privateInfoPost": "Aktiv"
      }
    },
    "facilityStastistics": {
      "location": "Lokasjonsinfo",
      "use": "kWh",
      "sessions": "Antall ladinger",
      "chargingTime": "Ladetid",
      "chargingAmount": "Totalt ladebeløp",
      "reimbursment": "Omsetningsfordeling",
      "fullReport": "Fullstendig rapport",
      "export": {
        "label": "Eksporter logg",
        "error": {
          "heading": "Error",
          "message": "Noe feilet under nedlasting av filen. Prøv igjen litt senere, eller kontakt Mer Support",
          "cancel": "Avbryt"
        }
      },
      "amountOfCharge": {
        "heading": "Totalt ladet",
        "informationHeadline": "Driftsforbruk ladeboks:",
        "informationText": "Så lenge ladeboksen er aktiv så vil den bruke noe strøm, uavhengig om laderen er i bruk. Dette er forbruk Mer ikke kan lese av, og pga av dette kan mengde energi (kWh) levert og forbruk oppgitt fra strømleverandør avvike noe."
      },
      "totalChargingAmount": {
        "heading": "Totalt ladebeløp"
      },
      "fliter": {
        "heading": "Filter",
        "noFilterSelected": "Alle lokasjoner, Alle kontrollere",
        "property": {
          "label": "Velg en lokasjon",
          "unselected": "Alle lokasjoner"
        },
        "controller": {
          "label": "Velg en kontroller",
          "unselected": "Alle kontrollere"
        },
        "button": {
          "submit": "Bruk filter"
        }
      }
    },
    "home": {
      "monthlyCharging": {
        "heading": "Månedlig lading"
      },
      "chargeDetailsGraph": {
        "heading": "",
        "noData": {
          "title": "Du har ikke ladet hos Mer ennå",
          "message": "Når du starter å lade hos oss får du se statistikk \u2028og oversikt over ladingen din her"
        }
      },
      "lastChargingSessions": {
        "heading": "Siste ladeøkter",
        "noData": "Du har ingen ladeøkter",
        "noDataInPeriod": "Du har ingen ladeøkter i denne perioden"
      },
      "aside": {
        "heading1": "Nytt for deg",
        "heading2": "Dine aktive fordeler"
      },
      "heading": "Velkommen til Mer, {{name}}",
      "nameInMenu": "Oversikt",
      "showVATInfo": "Alle beløp vises {{vatStatus}}"
    },
    "myChargers": {
      "heading": "Mine Ladere",
      "myChargerItem": {
        "graphTitle": "Forbrukeroversikt",
        "listHeadings": {
          "charingCode": "Ladekode",
          "location": "Lokasjon",
          "parkingLot": "Park. plass",
          "modell": "Modell",
          "maxEffect": "Maks effekt",
          "status": "Status",
          "cooperativesPrice": "Borettslagpris",
          "pricePerKwh": "NOK per kWh"
        },
        "kpis": {
          "totalThisMonth": "Totalt denne måneden",
          "totalChargingTime": "Total ladetid",
          "totalCost": "Total kostnad"
        }
      }
    },
    "contact": {
      "heading": "Kontakt oss"
    },
    "faq": {
      "heading": "FAQ"
    },
    "createContactPerson": {
      "heading": "Velkommen til Mer Hub",
      "text1": "I Mer Hub får du oversikt over forbruket i organisasjonen din, og du kan administrere dine medlemmer og ladebrikker.",
      "text2": "Vi skiller nå mellom brukere/administratorer og selve organisasjonen, så for å fullføre registreringen må vi opprette en administratorbruker for deg med egen epostadresse.",
      "text3": "Ta kontakt med kundeservice dersom du har spørsmål om administratorroller.",
      "continue": "Fortsett",
      "email": {
        "label": "Email address",
        "dontUseSame": "Dont use same email address"
      }
    },
    "noMatch": {
      "heading": "Fant ikke siden - 404"
    },
    "orderPayment": {
      "stepper": {
        "step1": "Fullfør bestilling",
        "step2": "Logg inn / registrer",
        "step3": "Betaling"
      },
      "heading": "Fullfør bestilling",
      "title": "Kundeinformasjon",
      "delivery": {
        "title": "Levering og installasjon",
        "deliveryMethod": "Standard frakt og installasjon (inkludert i pris)",
        "addressTitle": "Installasjonsadresse",
        "addressSummary": "{{addressLine1}}\n{{city}}, {{postalCode}}"
      },
      "payment": {
        "title": "Betalingsmetode"
      },
      "completeOrder": "Bekreft ordre",
      "orderSummary": {
        "title": "Ordresammendrag",
        "orderValue": "Ordresum",
        "delivery": "Frakt",
        "standardInstallation": "Standard installasjon",
        "totalCost": "Totalsum",
        "taxIncluded": "Inkl. mva",
        "discount": "10% rabatt for deg som Volvo-kunde i Mer sitt offentlige ladenettverk",
        "editOrder": "Endre ordre",
        "proceedButton": "Gå videre - totalpris"
      },
      "selectCharger": {
        "heading": "Velg lader",
        "taxIncluded": "Inkl. mva"
      },
      "operatingServices": {
        "heading": "Fordeler og tjenester",
        "listItem1": "10% rabatt for deg som Volvo-kunde i Mer sitt offentlige ladenettverk",
        "listItem2": "Fakturering basert på eget forbruk",
        "listItem3": "Full oversikt over forbruk og faktura",
        "listItem4": "24/7 teknisk support"
      },
      "productDetails": {
        "technicalSpecifications": "Teknisk informasjon",
        "installationInformation": "Informasjon om standard installasjon"
      }
    },
    "orderComplete": {
      "heading": "Tusen takk for din bestilling!",
      "yourOrderNumber": "Ditt ordrenummer:",
      "confirmation": "En bekreftelsese-post har blitt sendt til den oppgitte epostadressen:",
      "title": "Velkommen til Mer",
      "message1": "Opplev vår elektriske univers og logg deg inn på Mer Hub! ",
      "message2": "Ikke gå glipp av din eksklusive Volvo-fordel - gå inn på Mer Hub og aktiver den, slik at du får 10% rabatt i vårt offentlige ladenettverk! Denne fordelen får du i tillegg til de andre gode kundefordelene hos Mer.",
      "goToHub": "Gå til Min side"
    },
    "privacy": {
      "heading": "Personvern"
    },
    "account": {
      "navigation": {
        "title": "Min brukerprofil"
      },
      "subpage": {
        "info": {
          "heading": "Personlig informasjon",
          "submitSuccess": "Dine endringer er lagret",
          "missingDetailsHeading": "Legg til personlig informasjon",
          "missingDetailsInstructions": "Obs! Vi mangler viktig informasjon, for å fortsette må du fylle ut postadressen og lagre",
          "missingDetails": {
            "address": "full address",
            "payment": "payment",
            "paymentCardExpired": "paymentCardExpired",
            "paymentCardExpiring": "paymentCardExpiring",
            "phone": "Phone Number"
          },
          "postAddress": "Postadresse",
          "postAddressDescription": "Dette er adressen vi vil sende RFID-brikker til."
        },
        "vehicles": {
          "heading": "Kjøretøy",
          "introduction": "Du kan legge til kjøretøy på kontoen.",
          "addVehicle": "Legg til nytt kjøretøy"
        },
        "rfid": {
          "heading": "Ladebrikker og nettverk",
          "introduction": "Her har du oversikt over alle dine ladebrikker.",
          "rfidTags": "Ladebrikker",
          "orderNewTag": "Bestill ny ladebrikke",
          "addExistingTag": "Legg til ny ladebrikke",
          "blocked": "Kundekontoen er blokkert og du kan derfor ikke bestille eller legge til ny ladebrikker.",
          "blockedMember": "Ditt nettverk er blokkert og du kan derfor ikke bestille eller legge til ny ladebrikke.",
          "invalidPaymentMethod": "Legg til betalingsmteode for å administrere ladebrikker.",
          "rfidTag": {
            "status": {
              "active": "Aktiv"
            },
            "edit": "Rediger"
          },
          "rfidOrder": {
            "description": "Ordrebehandling"
          }
        },
        "network": {
          "subtitle": "Mitt nettverk: {{network}}",
          "cooperativeSubtitle": "Mitt borettslag: {{network}}",
          "memberCountPrefix": "Du betaler nå for",
          "memberCountSuffix": "kontoer.",
          "addMembersPrefix": "Du kan legge til opptil",
          "addMembersSuffix": "flere familiemedlemmer.",
          "addMember": "Legg til medlem",
          "removeMember": "Koble fra",
          "revokeInvitation": "Avbryt invitasjon",
          "memberOf": "Du er registrert som {{role}} av nettverket {{network}}.",
          "beboer": "Du eier en hjemmelader som er tilknyttet dette borettslaget.",
          "beboerInfo": "Administrator av ladeanlegget du er tilknyttet vil kunne se teknisk informasjon for din ladeboks og få en overordnet oversikt over forbruket i kilowattimer.",
          "memberInstructions": "Om du ønsker å opprette ditt eget nettverk og ta ansvar for faktureringen, må du først fjerne den aktive koblingen. ",
          "memberBlocked": "Ditt nettverk er blokkert for å starte lading, ta kontakt med administrator av nettverket ditt.",
          "memberBlockedInstructions": "Ønsker du å koble deg fra nettverket? \n1. Koble deg fra nettverket\n2. Velg betalingsmetode \n3. Kontakt kundeservice for å oppheve blokkering",
          "removeOwnMembership": "Fjern kobling",
          "noNetwork": {
            "subtitle": "Mitt nettverk",
            "instructions": "Sammen er vi Mer! Inviter opptil 4 medlemmer så dere raskere kan spare opp volumrabatt og få alt samlet på én faktura.",
            "membersCount": "Du har ingen aktive medlemmer."
          },
          "instructions": "Sammen er vi Mer! Inviter opptil {{maxMembers}} medlemmer så dere raskere kan spare opp volumrabatt og få alt samlet på én faktura.",
          "membersCount": "Antall medlemmer du betaler for nå er: {{members}}.",
          "noMembers": "Du har ingen aktive medlemmer.",
          "invitationsAvailable": "Du har fortsatt {{invites}} invitasjoner tilgjengelig.",
          "noInvitationsAvailable": "Du har ingen flere invitasjoner tilgjengelige",
          "blocked": "Kundekontoen er blokkert og du kan derfor ikke legge til medlem.",
          "invalidPaymentMethod": "Legg til betalingsmteode for å administrere nettverk."
        },
        "payment": {
          "heading": "Betaling og faktura",
          "methods": {
            "memberOfPrefix": "Du er medlem av",
            "memberOfSuffix": "sitt nettverk, som tar seg av betaling for din lading.",
            "memberInstructions": "Om du vil betale selv må du fjerne koblingen til dette nettverket.",
            "changeCard": "Endre betalingskort",
            "changeMethod": "Endre betalingsmetode",
            "missing": "Ingen gyldig betalingsmetode. Legg til betalingskort for å lade."
          },
          "method": {
            "card": {
              "introduction": "Du har valgt å betale med betalingskort. Betaling trekkes umiddelbart etter endt lading."
            },
            "eInvoice": {
              "introduction": "Du har valgt å betale med epostfaktura. Faktureres månedlig."
            }
          },
          "invoices": {
            "heading": "Betalingsoversikt",
            "memberInstructions": "Siden du er medlem av et nettverk får du kun fakturaer fra før du ble medlem i din portal. Snakk med administrator for ditt nettverk dersom du har spørsmål om nyere betalinger.",
            "status": "Status",
            "invoiceNumber": "Fakturanr.",
            "downloadInvoice": "Faktura",
            "downloadOrderLines": "Underlag",
            "date": "Dato",
            "amount": "Beløp",
            "more": "Vis flere",
            "noInvoices": "Du har ikke mottatt noen fakturaer ennå",
            "showVATInfo": "Beløp {{vatStatus}}",
            "downloads": {
              "pdf": "Faktura (PDF)",
              "csv": "Underlag (CSV)",
              "xls": "Underlag (XLSX)"
            },
            "statuses": {
              "PAID": "Betalt",
              "UNPAID": "Ikke betalt",
              "CREDITED": "Kreditnota",
              "CREDIT_CARD_FAILED": "Betalingsfeil",
              "CANCELLED": "Kansellert",
              "PREPARED_FOR_INVOICE": "Ikke betalt",
              "ZERO": ""
            },
            "filter": {
              "filter": "Filter",
              "dateRange": "Date range"
            }
          },
          "updateSuccess": "Dine endringer er lagret"
        },
        "subscriptions": {
          "heading": "Abonnementer",
          "intro": "Her har du oversikt over dine abonnementer og løpende kostnader.",
          "other": "Andre abonnementer",
          "showVATInfo": "Alla priser er {{vatStatus}}"
        },
        "settings": {
          "heading": "Kontoinnstillinger",
          "email": {
            "heading": "Epostadressen",
            "introduction": "Din epostadresse: ",
            "changeEmail": "For å endre epostadressen må du oppgi ny adresse og ditt eksisterende passord. Du vil da motta en lenke på den nye adressen for å fullføre endringen.",
            "button": "Endre epostadressen"
          },
          "accountNumber": {
            "heading": "Kundenummer",
            "introduction": "Din kundenummer: "
          },
          "password": {
            "heading": "Passord",
            "changePassword": "For å endre passordet ditt kan du logge ut fra Mer Hub og bruke “Glemt passord?” funksjonen.",
            "changePasswordLink": "Endre passordet ditt"
          },
          "login": {
            "introduction": "Dersom du ønsker å endre eposten din kan du ",
            "link_text": "kontakte kundesupport.",
            "changeLogin": "For å endre passordet ditt kan du logge ut fra Mer Hub og bruke “Glemt passord?” funksjonen."
          },
          "language": {
            "heading": "Språk for portalen"
          },
          "agreement": {
            "heading": "Samtykker"
          },
          "deleteAccount": {
            "heading": "Slett konto",
            "button": "Slett konto"
          },
          "deleteFamilyAccount": {
            "info": "Du har {{memberCount}} aktive medlemmer i nettverket ditt. Før du sletter kontoen din må du fjerne aktive koblinger i"
          },
          "personalData": {
            "heading": "Brukerdata og GDPR",
            "introduction": "I henhold til GDPR lovverket kan du be oss om all data tilknyttet din personlige Mer bruker. Ved forespørsel vil du motta en epost med link for nedlastning av data fra Mer Hub.",
            "requestGDPRExport": "Send forespørsel om persondata",
            "requestingGDPRExport": "Sender forespørsel om persondata...",
            "requestedGDPRExport": "Forespørsel om persondata er sendt",
            "downloadGDPRExport": "Last ned persondata",
            "label": "Persondata",
            "exportList": {
              "heading": "Hentet data",
              "name": "Navn",
              "createdDate": "Oprettet dato",
              "expiryDate": "Utløpsdato",
              "showMore": "Vis flere"
            }
          }
        }
      }
    },
    "organization": {
      "subpage": {
        "profile": {
          "heading": "Organisasjonsprofil",
          "missingAddressHeading": "Legg til organisasjonsprofil",
          "missingAddressInstructions": "Obs! Vi mangler viktig informasjon, for å fortsette må du fylle ut manglende detaljer og lagre.",
          "button": {
            "submit": "Lagre endringer"
          },
          "submitSuccess": "Dine endringer er lagrede",
          "invoiceAddressIsSameAsBusinessAddress": "Samme som organisasjonens postadresse",
          "invoiceAddressLocked": "Ønsker du å endre din organisasjons fakturaadresse kan du ta kontakt med kundeservice på tlf: +47 47 67 08 00",
          "invoiceAddress": "Fakturaadresse",
          "businessAddress": "Organisasjonens postadresse",
          "businessAddressDescription": "Dette er adressen vi vil sende RFID-brikker til."
        },
        "settings": {
          "heading": "Kontoinnstillinger"
        },
        "rfid": {
          "navigation": {
            "heading": "RFID brikker",
            "title": "RFID brikker"
          },
          "heading": "RFID brikker",
          "button": {
            "order": "Bestill brikker",
            "activate": "Aktivér brikker"
          },
          "search": "Finn brikker",
          "list": {
            "heading": {
              "serialNumber": "Serienr.",
              "status": "Status",
              "description": "Notat"
            },
            "status": {
              "active": "Aktiv",
              "inactive": "Blokkert"
            }
          }
        },
        "inviteMembers": {
          "heading": "Invitér medlemmer",
          "formHeading": "Invitasjoner",
          "intro": "Som administrator kan du invitere dine ansatte som medlemmer.",
          "instructions": "Som medlem av Bedriften kan de ansatte benytte Mers ladeapp \"Mer Connect\" for å starte og stoppe lading. Lading utført av bedriftens medlemmer blir inkludert i bedriftens samlefaktura.\n\nSe \"nettverk\" i menyen til venstre for oversikt over bedriftens medlemmer.",
          "note": "Når invitasjonen er akseptert vil organisasjonen belastes for medlemmets fremtidige ladeøkter.",
          "button": {
            "addInvite": "Legg til epostadresse",
            "removeInvite": "Fjern epostadresse",
            "submit": "Send invitasjoner",
            "cancel": "Avbryt"
          }
        },
        "payment": {
          "heading": "Betaling og faktura",
          "paymentMethodHeading": "Betalingsmetode",
          "paymentMethodInstructions": "Dersom du ønsker epostfaktura eller EHF må du ta kontakt med vår kundeservice på telefon: +47 476 70 800",
          "missing": "Ingen gyldig betalingsmetode. Legg til/endre betalingsmetode.",
          "wrongEhf": "Ingen gyldig betalingsmetode. Bekreft betalingsmetode for å lade.",
          "updateSuccess": "Dine endringer er lagret",
          "method": {
            "ehf": {
              "heading": "EHF Detaljer",
              "introduction": "Du har valgt EHF-faktura.\n\nFaktureres månedlig."
            },
            "eInvoice": {
              "heading": "E-postadresse for epostfaktura.",
              "introduction": "Du har valgt å betale med epostfaktura.\n\nFaktureres månedlig.",
              "email": "E-postadresse for epostfaktura. Kontakt kundeservice for å endre denne."
            },
            "card": {
              "heading": "Betalingskort Detaljer",
              "introduction": "Du har valgt å betale med betalingskort. Betaling trekkes umiddelbart etter endt lading."
            }
          },
          "button": {
            "change": "Endre betalingsmetode"
          }
        }
      }
    },
    "membership": {
      "loading": {
        "message": "Adding membership..."
      },
      "error": {
        "heading": "Membership error",
        "message": "There was a problem processing this membership, please check your details and try again"
      },
      "coop": {
        "heading": "Velg COOP Medlemsskap",
        "instructions": "Vi fant følgende COOP-medlemskap for deg, velg den du vil registrere deg hos Mer:",
        "memberNumber": "Medlemsnr. #{{membershipId}}",
        "coopAccount": "Active COOP Account",
        "coopId": "COOP-ID: #{{coopId}}",
        "disabled": "Aktivering og deaktivering av Ladebonus er dessverre ikke tilgjengelig for øyeblikket, grunnet oppdateringer i Coop sitt system. Vennligst forsøk på nytt mandag 14. mars",
        "loading": {
          "add": "Legger til Coop-medlemskap",
          "remove": "Fjerning av Coop-medlemskap"
        },
        "button": {
          "anotherAccount": "Logg inn med annen COOP-konto",
          "anotherMembership": "Velg et annet COOP-medlemskap",
          "cancel": "Avbryt",
          "continue": "Fortsette",
          "tryAgain": "Prøv igjen"
        },
        "error": {
          "heading": {
            "connect": "Registrere COOP-medlemskap",
            "disconnect": "Deaktiver COOP-medlemskap",
            "currentCoopAccount": "Du er for øyeblikket logget på COOP med konto"
          },
          "connect": "Ooops! Noe gikk galt. Vi klarte ikke å registrere ditt Coop-medlemskap. \n\nDu kan prøve igjen med en annen Coop-konto. Dersom problemet gjentar seg, ta kontakt med COOP sin kundestøtte.",
          "disconnect": "Ooops! Noe gikk galt. Vi kunne ikke deaktivére Ladebonus. Prøv igjen, eller ta kontakt med Coops kundestøtte."
        }
      }
    },
    "invitation": {
      "FAMILY": {
        "821200000": {
          "heading": "",
          "instructions": ""
        },
        "821200001": {
          "heading": "{{invitedBy}} har invitert deg til nettverket sitt.",
          "instructions": "Dette nettverket gjør det enkelt for venner og familie å samle all ladeinformasjon og betaling. Ved å akseptere invitasjonen vil fremtidige ladeøkter og transaksjoner bli synlig for administratoren, som også er ansvarlig for betaling."
        },
        "821200002": {
          "heading": "",
          "instructions": ""
        },
        "821200003": {
          "heading": "",
          "instructions": ""
        },
        "000000001": {
          "heading": "",
          "instructions": ""
        }
      },
      "COMPANY": {
        "821200000": {
          "heading": "",
          "instructions": ""
        },
        "821200001": {
          "heading": "Du har blitt invitert til {{invitationOrganizationName}} sitt nettverk.",
          "instructions": "Dersom du blir med i nettverket vil {{invitationOrganizationName}} betale for alle ladeøktene dine. Administrator i nettverket vil få oversikt over ladeinformasjon og transaksjoner."
        },
        "821200002": {
          "heading": "Velkommen til Mer Hub, den nye kundeportalen.",
          "instructions": "I Mer Hub kan du administrere ansatte, bestille RFID brikker, og ha full kontroll på forbruk og lading. Dersom organisasjonen har et ladeanlegg får du også oversikt over omsetning.\n\nVi skiller nå mellom brukere/administratorer og selve organisasjonen, så for å fullføre registreringen i ny portal må vi opprette en administratorbruker for deg.\n\nOm du har en bruker i Mer Hub allerede kan du logge inn med denne, og veksle mellom egen lading og administrasjon av organisasjonen din.",
          "heading_migrated": "Velkommen til Mer Hub, den nye kundeportalen.",
          "instructions_migrated": {
            "introduction": "I Mer Hub kan du administrere ansatte, bestille RFID brikker, og ha full kontroll på forbruk og lading. Dersom organisasjonen har et ladeanlegg får du også oversikt over omsetning.",
            "explanation": "Vi skiller nå mellom brukere/administratorer og selve organisasjonen, så for å fullføre registreringen i ny portal må vi opprette en administratorbruker for deg.",
            "explanation_email": "PS. Du må bruke en annen epostadresse enn den du har brukt i Min Side.",
            "alternatives": "Om du har en bruker i Mer Hub allerede kan du logge inn med denne, og veksle mellom egen lading og administrasjon av organisasjonen din."
          }
        },
        "821200003": {
          "heading": "",
          "instructions": ""
        },
        "000000001": {
          "heading": "",
          "instructions": ""
        }
      },
      "PUBLIC": {
        "821200000": {
          "heading": "",
          "instructions": ""
        },
        "821200001": {
          "heading": "Du har blitt invitert til {{invitationOrganizationName}} sitt nettverk.",
          "instructions": "Dersom du blir med i nettverket vil {{invitationOrganizationName}} betale for alle ladeøktene dine. Administrator i nettverket vil få oversikt over ladeinformasjon og transaksjoner. "
        },
        "821200002": {
          "heading": "Velkommen til Mer Hub, den nye kundeportalen.",
          "instructions": "I Mer Hub kan du administrere ansatte, bestille RFID brikker, og ha full kontroll på forbruk og lading. Dersom organisasjonen har et ladeanlegg får du også oversikt over omsetning.\n\nVi skiller nå mellom brukere/administratorer og selve organisasjonen, så for å fullføre registreringen i ny portal må vi opprette en administratorbruker for deg.\n\nOm du har en bruker i Mer Hub allerede kan du logge inn med denne, og veksle mellom egen lading og administrasjon av organisasjonen din.",
          "heading_migrated": "Velkommen til Mer Hub, den nye kundeportalen.",
          "instructions_migrated": {
            "introduction": "I Mer Hub kan du administrere ansatte, bestille RFID brikker, og ha full kontroll på forbruk og lading. Dersom organisasjonen har et ladeanlegg får du også oversikt over omsetning.",
            "explanation": "Vi skiller nå mellom brukere/administratorer og selve organisasjonen, så for å fullføre registreringen i ny portal må vi opprette en administratorbruker for deg.",
            "explanation_email": "PS. Du må bruke en annen epostadresse enn den du har brukt i Min Side.",
            "alternatives": "Om du har en bruker i Mer Hub allerede kan du logge inn med denne, og veksle mellom egen lading og administrasjon av organisasjonen din."
          }
        },
        "821200003": {
          "heading": "",
          "instructions": ""
        },
        "000000001": {
          "heading": "",
          "instructions": ""
        }
      },
      "COOPERATIVE": {
        "821200000": {
          "heading": "",
          "instructions": ""
        },
        "821200002": {
          "heading": "Mer har invitert deg til å administrere {{invitationOrganizationName}}.",
          "instructions": "Som administrator av {{invitationOrganizationName}} kan du legge til beboere og ansatte, samt se status for utbetaling av omsetningsfordeling dersom det er en del av din avtale med Mer.",
          "heading_migrated": "Velkommen til Mer Hub, den nye kundeportalen.",
          "instructions_migrated": {
            "introduction": "I Mer Hub kan du legge til beboere og ansatte, samt se status forutbetaling av omsetningsfordeling dersom det er en del av din avtale med Mer.",
            "explanation": "Vi skiller nå mellom brukere/administratorer og selve organisasjonen, så for å fullføre registreringen i ny portal må vi opprette en administratorbruker for deg.",
            "explanation_email": "PS. Du må bruke en annen epostadresse enn den du har brukt i Min Side.",
            "alternatives": "Om du har en bruker i Mer Hub allerede kan du logge inn med denne, og veksle mellom egen lading og administrasjon av borettslaget ditt."
          }
        },
        "821200001": {
          "heading": "Du har blitt invitert som ansatt i nettverket til {{invitationOrganizationName}}.",
          "instructions": "Dersom du aksepterer invitasjonen vil {{invitationOrganizationName}} betale for alle ladeøktene dine. Administrator i nettverket vil få oversikt over dine fremtidige ladeøkter og transaksjoner. "
        },
        "821200003": {
          "heading": "Du har blitt invitert til {{invitationOrganizationName}} sitt nettverk.",
          "instructions": "{{invitationOrganizationName}} har et ladeanlegg fra Mer, og som beboer her har du nå mottatt en invitasjon for å bli med i deres nettverk."
        },
        "000000001": {
          "heading": "",
          "instructions": ""
        }
      },
      "MANUFACTURER_VENDOR": {
        "821200000": {
          "heading": "",
          "instructions": ""
        },
        "821200001": {
          "heading": "",
          "instructions": ""
        },
        "821200002": {
          "heading": "",
          "instructions": ""
        },
        "821200003": {
          "heading": "",
          "instructions": ""
        },
        "000000001": {
          "heading": "",
          "instructions": ""
        }
      },
      "PRIVATE": {
        "821200000": {
          "heading": "",
          "instructions": ""
        },
        "821200001": {
          "heading": "",
          "instructions": ""
        },
        "821200002": {
          "heading": "",
          "instructions": ""
        },
        "821200003": {
          "heading": "",
          "instructions": ""
        },
        "000000001": {
          "heading": "",
          "instructions": ""
        }
      },
      "SERVICE_PARTNER": {
        "821200000": {
          "heading": "",
          "instructions": ""
        },
        "821200001": {
          "heading": "",
          "instructions": ""
        },
        "821200002": {
          "heading": "",
          "instructions": ""
        },
        "821200003": {
          "heading": "",
          "instructions": ""
        },
        "000000001": {
          "heading": "",
          "instructions": ""
        }
      },
      "constraint": {},
      "reload": {
        "prefix": "Når dette er gjort kan du ",
        "linkText": "laste inn invitasjonen på nytt",
        "suffix": " ."
      },
      "coop": {
        "heading": "Hva skjer med min Ladebonus?",
        "instructions": {
          "COMPANY": "Som medlem av et bedriftsnettverk vil du få tilgang på bedriften sine rabatter. Du vil dermed ikke få Ladebonus på lading mens du er medlem av nettverket. \n\nDersom du ved en senere anledning melder deg ut av nettverket, vil din gamle Ladebonus bli automatisk reaktivert såfremt ditt Coop-medlemskap fortsatt er aktivt.",
          "COOPERATIVE": "Som medlem av et bedriftsnettverk vil du få tilgang på bedriften sine rabatter. Du vil dermed ikke få Ladebonus på lading mens du er medlem av nettverket. \n\nDersom du ved en senere anledning melder deg ut av nettverket, vil din gamle Ladebonus bli automatisk reaktivert såfremt ditt Coop-medlemskap fortsatt er aktivt.",
          "FAMILY": "Siden betaling gjøres av administrator, er det også denne personen som har rett på alle kundefordelene ved lading. Du som nettverksmedlem vil derfor ikke få Coop medlemsbonus på gjennomførte ladeøkter.\n\nDersom du ved en senere anledning melder deg ut av nettverket, vil din gamle Ladebonus bli reaktivert automatisk såfremt ditt Coop-medlemskap fortsatt er aktivt.",
          "MANUFACTURER_VENDOR": "",
          "PRIVATE": "",
          "PUBLIC": "Som medlem av et bedriftsnettverk vil du få tilgang på bedriften sine rabatter. Du vil dermed ikke få Ladebonus på lading mens du er medlem av nettverket. \n\nDersom du ved en senere anledning melder deg ut av nettverket, vil din gamle Ladebonus bli automatisk reaktivert såfremt ditt Coop-medlemskap fortsatt er aktivt.",
          "SERVICE_PARTNER": ""
        }
      },
      "contactSupport": {
        "prefix": "Ta kontakt med ",
        "link": "kundeservice",
        "suffix": " dersom du har spørsmål om administratorroller"
      },
      "button": {
        "accept": "Akseptér invitasjonen",
        "acceptAndLogin": "Aksepter og logg inn",
        "cancel": "Avbryt",
        "login": "Logg inn",
        "register": "Registrer deg"
      },
      "loading": {
        "message": "Laster invitasjon..."
      },
      "error": {
        "heading": "Invitasjonslenken fungerer ikke.",
        "message": "En feil oppstod. Det kan være at invitasjonen ikke er gyldig, vennligst prøv igjen eller spør om en ny invitasjon."
      },
      "invalid": {
        "heading": "Invitasjonslenken fungerer ikke.",
        "message": "En feil oppstod. Det kan være at invitasjonen ikke er gyldig, vennligst prøv igjen eller spør om en ny invitasjon."
      },
      "invalidUserType": {
        "heading": "Kan ikke godta invitasjonen Cannot accept invitation",
        "message": "Du er allerde admin eller medlem av et nettverk. \nYou are already a member or admin of a family network"
      },
      "expired": {
        "heading": "Invitasjonslenken er utgått.",
        "message": "Invitasjon fra {{network}} utgått eller den er ikke gyldig lenger. Vennligst spør om en ny invitasjon."
      },
      "accepting": {
        "message": "Godtar invitasjon..."
      },
      "accepted": {
        "message": "Invitajonen er godtatt"
      },
      "declined": {
        "message": "Invitasjonen er avvist"
      },
      "ACCOUNT_MIGRATION": {
        "welcome": "Hei og velkommen til vår kundeportal Mer Hub!",
        "heading": "Du er kun noen få steg igjen for å få satt opp en admin for organisasjonen i Mer Hub!",
        "introduction": "Her får du full oversikt over ditt ladeanlegg, ladehistorikk - i tillegg til at du kan administrere brukere og bestille RFID brikker!",
        "important": "Vi skal nå gjøre deg til admin av denne organisasjonen, og trenger litt mer info:",
        "constraint": "For å administrere {{organizationName}} må du velge en annen bruker eller opprette ny bruker her. Du kan også lese mer om hvordan du skal gå frem i vår ",
        "constraint_faq_link_lable": "Mer Hub FAQ her!",
        "action": {
          "login": {
            "instructions": "Jeg har allerede en personlig brukerkonto hos Mer som jeg ønsker å bruke som min admin bruker!",
            "button": "Logg inn"
          },
          "register": {
            "instructions": "Jeg ønsker å opprette en ny personlig brukerkonto hos Mer som kan brukes som min admin bruker!",
            "button": "Registrer"
          },
          "accept": {
            "instructions": "I want to use my current personal account to be used as an admin account",
            "button": "Accept"
          },
          "cancel": {
            "button": "Tilbake"
          }
        },
        "support": {
          "faq": {
            "heading": "FAQ",
            "tagLine": "Her finner du guide og ofte stilte spørsmål i forbindelse med Mer Hub portalen!",
            "link": "Finn svar på mitt spørsmål her"
          },
          "contact": {
            "heading": "Kundesupport",
            "tagLine": "Våre dyktige kunderådgivere står klare til å hjelpe deg med spørsmål omkring kundeportal mandag - fredag kl 08-15!",
            "phone": "+47 47 67 08 00",
            "form": "Kontaktskjema"
          }
        }
      },
      "CUSTOMER_SUPPORT": {
        "welcome": "Hei og velkommen til vår kundeportal Mer Hub!",
        "heading": "Mer har invitert deg til å administrere {{invitationOrganizationName}}.",
        "introduction": "Som administrator av {{invitationOrganizationName}} kan du legge til beboere og ansatte, samt se status for utbetaling av omsetningsfordeling dersom det er en del av din avtale med Mer.",
        "important": "Vi skal nå gjøre deg til admin av denne organisasjonen, og trenger litt mer info:",
        "constraint": "For å administrere {{organizationName}} må du velge en annen bruker eller opprette ny bruker her. Du kan også lese mer om hvordan du skal gå frem i vår ",
        "constraint_faq_link_lable": "Mer Hub FAQ her!",
        "action": {
          "login": {
            "instructions": "Jeg har allerede en personlig brukerkonto hos Mer som jeg ønsker å bruke som min admin bruker!",
            "button": "Logg inn"
          },
          "register": {
            "instructions": "Jeg ønsker å opprette en ny personlig brukerkonto hos Mer som kan brukes som min admin bruker!",
            "button": "Registrer"
          },
          "accept": {
            "instructions": "I want to use my current personal account to be used as an admin account",
            "button": "Accept"
          },
          "cancel": {
            "button": "Tilbake"
          }
        },
        "support": {
          "faq": {
            "heading": "FAQ",
            "tagLine": "Her finner du guide og ofte stilte spørsmål i forbindelse med Mer Hub portalen!",
            "link": "Finn svar på mitt spørsmål her"
          },
          "contact": {
            "heading": "Kundesupport",
            "tagLine": "Våre dyktige kunderådgivere står klare til å hjelpe deg med spørsmål omkring kundeportal mandag - fredag kl 08-15!",
            "phone": "+47 47 67 08 00",
            "form": "Kontaktskjema"
          }
        }
      },
      "migratedInvite": {
        "adminExists": {
          "heading": "Denne kontoen er migrert.",
          "alert": "Det er allerede opprettet en administratorkonto for {{name}}.\nInnloggingen fra Min Side kan ikke brukes for å administrere organisasjonen i Mer Hub.",
          "message": "For å administrere organisasjonen din må du logge inn med administratorkontoen, {{email}}.",
          "contactSupport": {
            "prefix": "Ta kontakt med ",
            "link": "kundeservice",
            "suffix": " dersom du har spørsmål om administratorroller."
          },
          "button": {
            "logout": "Logg ut og bruk en annen konto"
          }
        },
        "constraint": "Rollekonflikt"
      }
    },
    "addMembership": {
      "invalid": {
        "heading": "Can't add membership",
        "message": "The details provided are incomplete or invalid"
      }
    },
    "learnMore": "Lær mer",
    "free": "Gratis",
    "zeroPrice": "0,-",
    "included": "Inkludert",
    "cancelSubscription": "Si opp abonnement",
    "registration": {
      "subpage": {
        "userService": {
          "heading": "Tjenester"
        },
        "memberService": {
          "heading": "Tjenester"
        },
        "company": {
          "heading": "Registrering"
        },
        "userPayment": {
          "heading": "Betaling",
          "loading": "Legger til betalingsmetode...",
          "authorizing": "Godkjenner betalingsmetode..."
        },
        "user": {
          "heading": "Registrering",
          "suggestLogin": "Log in med en annen bruker"
        }
      }
    },
    "authentication": {
      "error": {
        "heading": "En autentiseringsfeil oppstod.",
        "general": "Autentiseringen feilet.",
        "returnToLogin": "Returner til login",
        "returnToHub": "Gå til Mer Hub",
        "EMAIL_VERIFICATION_REQUIRED": {
          "heading": "Autentifisering av epostadresse",
          "message": "Mer ønsker å unngå at din epostadresse blir misbrukt. Derfor er det fint om du kan åpne din innboks og verifisere kontoen gjennom mottatt epost.\n\nVi ser frem til at du fullfører din kontoopprettelse hos Mer!"
        },
        "APP_UPGRADE_REQUIRED": {
          "message": "Vi har gjort en oppgraderinger i appen som inneholder viktige feilrettinger. Du må oppdatere appen før du kan ta den i bruk."
        }
      },
      "migrate": {
        "heading": "Ny innloggingsmetode",
        "introduction": "Siden vi oppgraderer innlogingen vår, må du velge en ny innloggingsmetode for Mer Hub webportalen vår og Mer Connect mobilappen vår.",
        "expirationDatePrefix": "Du må legge til en innloggingsmetode før",
        "expirationDateSuffix": "for å fortsette å bruke Mer Hub og Mer Connect.",
        "success": "Flott! Du har nå valgt ny innloggingsmetode!",
        "linkExistingOnEmailExists": "Link denne eposten"
      },
      "manage": {
        "heading": "Dine innlogginger",
        "introduction": "Du kan bli bedt om å gi tilgang til kontoen din.",
        "support": "Ta kontakt med kundeservice dersom du ikke kommer videre.",
        "continue": "Gå videre",
        "cancel": "Avbryt",
        "finish": "Gå til Mer Hub",
        "finishMobile": "Gå til Mer Connect",
        "addIdentity": "Legg til ny metode"
      },
      "link": {
        "heading": "Vi fant noen brukerkontoer som kan være dine.",
        "instruction": {
          "accountsFound": "Disse brukerkontoene tilhører kanskje deg. For å linke en brukerkonto, må du logge deg inn på denne kontoen i et annet vindu. \n\n Du kan finne disse kontoene under Kontoinstillinger > Kontoer hvis du ønsker å gjøre dette senere."
        },
        "skip": "Gå videre"
      },
      "addIdentity": {
        "heading": "Legg til ny innlogging",
        "orLinkEmailPassword": "Eller bruk og eksisterende e-post og passord:",
        "providers": {
          "google-oauth2": "Logg inn med Google",
          "facebook": "Logg inn med Facebook",
          "apple": "Logg inn med Apple",
          "auth0": "Logg inn med e-post og passord"
        },
        "button": {
          "submit": "Gå videre",
          "cancel": "Avbryt"
        }
      },
      "identity": {
        "primary": "I bruk",
        "expires": "Utgår den",
        "providers": {
          "sms": "Passordløst SMS login",
          "google-oauth2": "Google",
          "facebook": "Facebook",
          "apple": "Apple",
          "auth0": "epost og passord"
        },
        "validateEmail": "Validér epostadresse nå",
        "validateEmailSending": "Validrerings epost sending...",
        "validateEmailSent": "Validrerings epost sendt, sjekk din epost",
        "validateEmailFailed": "Validrerings epost sending mislyktes",
        "remove": "Fjerne",
        "removing": "Fjerning...."
      },
      "help": {
        "heading": "Kontodiagnostikk",
        "authentication": "Godkjenning",
        "profile": "Brukerprofil",
        "logIn": "Logg inn",
        "logOut": "Logg ut",
        "returnToLogin": "Returner til login",
        "returnToHub": "Gå til Mer Hub",
        "loadingAuth0": "Laster inn konto",
        "loadingProfile": "Laster profil",
        "profileNotFound": "Finner ingen samsvarende profil",
        "customerSupportEmail": "support@mer.hub.eco",
        "phone": "Telefon",
        "email": "E-post",
        "button": {
          "sendToSupport": "Send opplysningene mine til kundestøtte",
          "sendToSupportSent": "Sendt opplysningene mine til kundestøtte",
          "sendToSupportAsEmail": "Send opplysningene mine til kundestøtte via e-post"
        }
      }
    },
    "terms": {
      "linkLeadIn": "Jeg har lest og godtar Mer sine ",
      "link": "brukervilkår og betingelser",
      "heading": "Brukervilkår"
    },
    "migrate": {
      "organization": {
        "heading": "Du er nå i gang med å sette opp din organisasjon i Mer Hub",
        "start": "Slik gjør du det:",
        "information": "1. For å logge inn organisasjonen, må du benytte dens brukernavn (e-post) og passord som du har benyttet tidligere for å logge inn på Min Side.",
        "instructions": "2. Du vil deretter bli bedt om å logge deg inn med din private bruker eller opprette en ny privat bruker. Du vil da få admin rettigheter for organisasjonen på din private bruker.",
        "button": {
          "continue": "Sett i gang!",
          "cancel": "Avbryt"
        }
      }
    }
  },
  "label": {
    "kpi": {
      "totalLastMonth": "Ladet hittil denne måneden",
      "totalChargingTime": "Ladetid",
      "totalCost": "Kostnad",
      "totalSavings": "Sparing",
      "chargedIn": "Ladet i",
      "chargedInPeriod": "perioden"
    },
    "userType": {
      "private": "Privat"
    },
    "input": {
      "rfidState": {
        "none": "Ingen",
        "order": "Bestill",
        "existing": "Eksisterende"
      },
      "rfidSuppliers": {
        "mer": "Mer"
      },
      "rfidSupplier": "Laderbrikke leverandør",
      "rfidSerialNumber": "Ladebrikke nummer",
      "rfidDescription": "Ladebrikke beskrivelse",
      "vehicleRegistration": "Registreringsnummer",
      "vehicleName": "Bilens navn",
      "firstName": "Fornavn",
      "lastName": "Etternavn",
      "phoneNumber": "Telefonnummer",
      "email": "Epost",
      "receiveOffersAndNewsViaEmail": "Motta nyhetsbrev fra Mer med tilbud og oppdateringer",
      "paymentType": {
        "card": "Betalingskort",
        "invoice": "Epostfaktura ({{price}} kr per mnd, {{vatStatus}})",
        "ehf": "EHF"
      },
      "paymentCardNumber": "Betalingskortnummer",
      "paymentCardExpiry": "Utløpsdato",
      "paymentCardCVC": "Kort CVC",
      "emailConfirmation": "Epost bekreftelse",
      "companyNumber": "Organisasjonsnummer",
      "companyName": "Bedriftens navn",
      "companyPublic": "",
      "address": "Adresse",
      "postCode": "Postnr.",
      "postArea": "Område",
      "country": "Land"
    }
  },
  "footer": {
    "heading": {
      "customerSupport": "Teknisk support",
      "downloadTheApp": "Last ned appen"
    }
  },
  "link": {
    "getInTouch": "Kontakt oss",
    "faq": "FAQ",
    "terms": "Brukervilkår",
    "privacy": "Personvern",
    "title": {
      "home": "Hjem",
      "profile": "Profil"
    }
  },
  "payment": {
    "initializing": "Initialisering",
    "creating": "Legger til betalingsmetode...",
    "authorizing": "Godkjenner betalingsmetode...",
    "error": {
      "cancelled": "Betalingsmetoden feilet, bruker kansellerte transaksjonen",
      "error": "Betalingsmetoden feilen, vennligst forsøk igjen",
      "refused": "Betalingsmetoden ble avvist, vennligst forsøk en annen betalingsmetode"
    },
    "challenge": {
      "redirect": "Du vil bli videresendt til din betalingskort tilbyder, vennligst vent",
      "challenge": "Betalingsmetoden venter på informasjon, vennligst vent",
      "identify": "Betalingsmetoden venter på informasjon, vennligst vent"
    },
    "success": {
      "existing": "Eksisterende betalingsmetode fungerte ",
      "authorised": "Betalingen var vellykket",
      "pending": "Betalingsmetode ble godkjent og prosessen fortsetter",
      "received": "Betalingsmetode ble godkjent og prosessen fortsetter"
    },
    "label": {
      "newCard": "Bruk kort",
      "existingCard": "Bruk eksisterende kort",
      "updatePaymentMethod": "Bytt betalingsmetode"
    }
  },
  "order": {
    "error": {
      "unknown": "En feil oppstod under behandlingen av ordren, vennligst prøv igjen.",
      "missingProduct": "Det er ikke valgt noen produkter. Vennligst velg et produkt og prøv igjen."
    },
    "showListOfProviders": "Vis liste over leverandører",
    "hideListOfProviders": "Skjul liste over leverandører"
  },
  "memberships": {
    "membershipIsActive": "Dette abonnementet er aktivt og løper inntil du sier det opp"
  },
  "btn": {
    "text": {
      "save": "Lagre",
      "cancel": "Avbryt",
      "saveChanges": "Lagre endringer",
      "nextStep": "Gå til neste steg",
      "createPassword": "Create Password",
      "completeRegistration": "Fullfør registrering",
      "goToPayment": "Gå til betalingsmetode",
      "goToContactPerson": "Gå til kontaktperson",
      "backToPayment": "Tilbake til betalingsmetode",
      "backToCompanyProfile": "Tilbake til organisasjonsprofil"
    }
  },
  "products": {
    "rfidTag": {
      "price": 50
    },
    "COOP": {
      "name": "COOP"
    },
    "GoEasy": {
      "name": "Go Easy"
    },
    "Ladefordel": {
      "name": "Mer Verdi"
    }
  },
  "notifications": {
    "missingDetails": {
      "payment": {
        "message": "Brukerkontoen mangler gyldig betalingsmetode. Legg til en gyldig betalingsmetode for å starte lading, bestille/legge til ladebrikke og invitere medlemmer til nettverket.",
        "action": "Legg til betalingsmetode"
      },
      "paymentCardExpiring": {
        "message": "Ditt kort nærmer seg utløpsdato! For å unngå avbrudd i ladetjenestene hos Mer må du oppdatere din betalingsinformasjon før ditt kort når utløpsdato.",
        "action": "Legg til betalingsmetode"
      },
      "paymentCardExpired": {
        "message": "Ditt kort er utløpt! For å gjenopprette ladetjeneste hos Mer må du oppdatere din betalingsinformasjon.",
        "action": "Legg til betalingsmetode"
      },
      "address": {
        "message": "Vennligst legg til adressen din!",
        "action": "Legg til adressen"
      },
      "phone": {
        "message": "Vennligst legg til telefonnummeret ditt!",
        "action": "Legg til telefonnummeret"
      }
    },
    "emailVerificationRequired": {
      "messagePrefix": "Din epost adresse",
      "messageSuffix": "har ikke blitt bekreftet.",
      "action": "Send bekreftelse",
      "actionComplete": "Sendt"
    },
    "systemMaintenance": {
      "message": "Vær oppmerksom på at vi planlegger vedlikehold på Mer Hub den 09. januar mellom kl. 17.00 og 19.00.\nI løpet av denne tiden kan enkelte handlinger og funksjoner være utilgjengelige. Vi beklager eventuelle ulemper dette kan medføre. Hils alle oss i Mer."
    },
    "userDeleted": {
      "message": "Brukerkontoen din er slettet. Kontoen vil ikke lenger være aktiv etter din siste faktura er betalt. Ta kontakt kundeservice hvis du vil angre slettingen."
    },
    "accountBlocked": {
      "PRIVATE": {
        "message": "Din kundekonto er blokkert. For å aktivere kundekontoen må du betale utestående ladetransaksjoner. Gå til ladeappen for å enkelt betale utestående ladetransaksjoner og oppheve blokkering. Bruker du ikke ladeappen kan du kontakte kundeservice.",
        "contactSupport": "Kontakt kundeservice"
      },
      "MEMBER": {
        "message": "Ditt nettverk er blokkert. Kontakt administrator av nettverket ditt eller forlat nettverket for å starte lading nå. Gå til ladebrikker og nettverk for å administrere nettverket ditt.",
        "detachNetwork": "Ladebrikker og nettverk"
      },
      "COMPANY": {
        "message": "Din kundekonto er blokkert. For å aktivere kundekontoen må du betale utestående ladetransaksjoner. Ta kontakt med kundeservice for assistanse.",
        "contactSupport": "Kontakt kundeservice"
      }
    }
  },
  "busy": {
    "loading": {
      "page": "Laster siden...",
      "login": "Laster siden...",
      "data": "Laster data..."
    },
    "confirming": {
      "payment": "Bekrefter betaling..."
    },
    "finalize": {
      "user": [
        "Din bruker blir nå opprettet i Mer Hub.",
        "Dine data blir klargjort.",
        "Opprettelse kan ta omtrent 30 sekunder til 1 minutt."
      ],
      "organization": [
        "Din bruker for bedriften blir nå opprettet i Mer Hub.",
        "Dine data blir klargjort.",
        "Opprettelse kan ta omtrent 30 sekunder til 1 minutt."
      ]
    },
    "migrate": {
      "organization": [
        "Bakgrunnsdata blir verifisert",
        "Dine data blir klargjort.",
        "Opprettelse kan ta omtrent 30 sekunder til 1 minutt."
      ]
    }
  },
  "steps": {
    "migrateOrganization": {
      "step1": "Logg inn som organisasjon",
      "step2": "Tilknytt admin konto",
      "step3": "Ferdig!"
    }
  },
  "orderingNoMore": {
    "prefix": "Beklager, siden du ser etter er ikke aktiv lenger. ",
    "linkUrl": "https://no.mer.eco/ladelosninger/",
    "link": "Klikk her",
    "suffix": " for å lese om våre ladeløsninger."
  },
  "aftermarketOrderingNoMore": {
    "heading": "Denne tjenesten er ikke lenger tilgjengelig.",
    "introduction": "Ønsker du å komme i kontakt med oss kan du nå oss ved å fylle ut kontaktskjema under eller ringe vår døgnåpne kundeservice på + 47 47 67 08 00\n.",
    "linkUrl": "https://no.mer.eco/mer/kontakt-oss/",
    "link": "Klikk her",
    "suffix": " for å lese om våre ladeløsninger."
  },
  "errors": {
    "authentication": {
      "migrate": {
        "auth0_idp_error": "Brukeren eksisterer allerede",
        "USER_EXIST": "Eposten er allerede i bruk, link til denne eposten istedet?",
        "USER_EMAIL_EXIST": "Eposten er allerede i bruk",
        "USER_PHONE_EXIST": "Telefonnummeret er allerede i bruk",
        "POPUP_CLOSED": "Bruker har kansellert tilkobling av kontoer"
      },
      "link": {
        "cancelled": "Pop up ble stengt"
      }
    },
    "register": {
      "personalInfo": {
        "USER_EXIST": "Eposten og/eller telefonnummeret er allerede i bruk",
        "USER_EMAIL_EXIST": "Eposten er allerede i bruk",
        "USER_PHONE_EXIST": "Telefonnummeret er allerede i bruk",
        "INVALID_USER": "Brukeren har ikke brukerkonto hos Mer"
      },
      "payment": {},
      "service": {
        "INVALID_TOKEN": "Brikkenummer er ugyldig",
        "UNAVAILABLE_TOKEN": "Brikkenummer er allerede i bruk"
      }
    },
    "membership": {
      "MEMEBERSHIP_EXIST": "Dette medlemmet er allerede lagt til",
      "INVALID_USER": "Medlemsnummeret er ugyldig",
      "coop": {
        "COOP_AUTH_FAILED": "Det oppsto et problem med autentisering med Coop, prøv igjen",
        "COOP_INVALID_STATE": "Svaret fra Coop er ugyldig, prøv igjen",
        "MEMEBERSHIP_NOT_EXIST": "Medlemsnummeret finnes ikke",
        "MEMEBERSHIP_EXIST": "Dette Coop-medlemmet er allerede lagt til hos Mer.",
        "COOP_DELETE_MEMBERSHIP_ERROR": "Du har logget inn hos Coop med en annen konto enn hva som er registrert hos Mer.",
        "invalid_grant": "Coop økten er ikke lenger gyldig, prøv igjen",
        "INVALID_GRANT": "Coop-økten er ikke lenger gyldig, prøv igjen"
      }
    },
    "payment": {
      "unknown": "",
      "existing": "",
      "refusalCode": {
        "0": "Oisann! Det ser ut til å være noe galt med bankkortet ditt. Vennligst ta kontakt med banken din.",
        "1": "Oisann! Noe gikk galt! Sjekk at du har benyttet riktig legitimasjon, saldo eller benytt et annet kort.",
        "2": "Du kansellerte transaksjonen – vi håper du kommer tilbake!",
        "3": "Oisann! Det er ut til å være en nettverksfeil, beklager ubeleiligheten! Forsøk igjen senere eller ta kontakt med vår kundeservice for assistanse."
      }
    },
    "verifyEmail": {
      "NOT_FOUND": "Verifiseringen mislyktes",
      "CODE_EXPIRED": "Verifiseringslinken er utløpt",
      "undefined": ""
    },
    "global": {
      "USER_EXIST": "Brukeren eksisterer allerede",
      "EMAIL_EXISTS": "Eposten er allerede i bruk",
      "USER_EMAIL_EXISTS": "Eposten er allerede i bruk",
      "USER_PHONE_EXISTS": "Telefonnummeret er allerede i bruk",
      "ORGANIZATION_EMAIL_EXISTS": "Eposten er allerede i bruk",
      "ORGANIZATION_PHONE_EXISTS": "Telefonnummeret er allerede i bruk",
      "ORGANIZATION_NUMBER_EXISTS": "Organisasjonsnummer er allerede i bruk",
      "PHONE_NUMBER_ASSIGNED": "Telefonnummeret er allerede i bruk",
      "INVALID_USER": "Brukeren har ikke brukerkonto hos Mer",
      "INVALID_TOKEN": "Brikkenummer er ugyldig",
      "UNAVAILABLE_TOKEN": "Brikkenummer er allerede i bruk",
      "INVALID_COOP_MEMBERID": "Ugylding Coop Medlemsskapsnummerr",
      "INVALID_COUPON": "Kupongen er ugyldig eller allerede i bruk",
      "INVALID_ACCOUNTCLASSIFICATION": "Koden er kun til bruk for bedrifter. Velg din bedriftskonto i meny oppe i høyre hjørne. Har du ikke en bedriftskonto må det opprettes.",
      "COUPON_ALREADY_LINKED": "Kupongen er allerede i bruk",
      "NOT_FOUND": "Tjenesten er for øyeblikket utilgjengelig, prøv igjen senere",
      "VEHICLE_ALREADY_LINKED": "Dette kjøretøyet er allerede registrert",
      "UNKNOWN_ERROR": "En ukjent feil oppstod",
      "PAYMENT_CARD_TIMEOUT": "Oisann! Det er ut til å være en nettverksfeil, beklager ubeleiligheten! Forsøk igjen eller ta kontakt med vår kundeservice for assistanse.",
      "REQUEST_TIMEOUT": "Det er ut til å være en nettverksfeil, beklager ubeleiligheten! Forsøk igjen eller ta kontakt med vår kundeservice for assistanse.",
      "ORGANIZATION_EXIST": "En organisasjon med de samme detaljene eksisterer allerede",
      "PAYMENTTYPE_NONE": "Du kan ikke utføre denne handlingen uten en gyldig betalingsmetode",
      "INVALID_PAYMENTCARD": "Du kan ikke utføre denne handlingen uten en gyldig betalingsmetode",
      "USER_BLOCKED": "Din kundekonto er blokkert. For å aktivere kundekontoen må du betale utestående ladetransaksjoner. Gå til ladeappen for å enkelt betale utestående ladetransaksjoner og oppheve blokkering. Bruker du ikke ladeappen kan du kontakte kundeservice.",
      "INVALID_PROJECT_CODE": "Er prosjektkoden riktig tastet inn? Sjekk koden og prøv igjen.",
      "MEMEBERSHIP_EXIST": "Dette medlemmet er allerede lagt til"
    }
  },
  "roleConstraint": {
    "FAMILY_ADMIN_TO_FAMILY_ADMIN": {
      "heading": "",
      "warning": {
        "INVITATION": "Du er allerede {{constraintRoleName}} for et annet nettverk. For å kunne akseptere denne invitasjonen må du avvikle det eksisterende nettverket ditt. ",
        "REGISTRATION": ""
      }
    },
    "FAMILY_ADMIN_TO_FAMILY_MEMBER": {
      "heading": "",
      "warning": {
        "INVITATION": "Du er allerede {{constraintRoleName}} for et annet nettverk. For å kunne akseptere denne invitasjonen må du avvikle det eksisterende nettverket ditt. ",
        "REGISTRATION": ""
      }
    },
    "FAMILY_ADMIN_TO_COMPANY_MEMBER": {
      "heading": "",
      "warning": {
        "INVITATION": "Du er allerede {{constraintRoleName}} av et privat nettverk. For å kunne akseptere denne invitasjonen må du avvikle det eksisterende nettverket ditt.",
        "REGISTRATION": "Du er allerede {{constraintRoleName}} av et privat nettverk. For å kunne akseptere denne invitasjonen må du avvikle det eksisterende nettverket ditt."
      }
    },
    "FAMILY_ADMIN_TO_COOPERATIVE_MEMBER": {
      "heading": "",
      "warning": {
        "INVITATION": "Du er allerede {{constraintRoleName}} av et {{constraintOrganizationClassification}}. Du kan ikke være {{constraintRoleName}} av et {{constraintOrganizationClassification}} og {{invitationRoleName}} i et {{invitationOrganizationClassification}} samtidig.",
        "REGISTRATION": ""
      }
    },
    "FAMILY_MEMBER_TO_FAMILY_ADMIN": {
      "heading": "",
      "warning": {
        "INVITATION": "Du er allerede {{constraintRoleName}} av et annet {{constraintOrganizationClassification}}. For å kunne akseptere denne invitasjonen må du melde deg ut av det aktive nettverket. ",
        "REGISTRATION": ""
      }
    },
    "FAMILY_MEMBER_TO_FAMILY_MEMBER": {
      "heading": "",
      "warning": {
        "INVITATION": "Du er allerede {{constraintRoleName}} av et annet {{constraintOrganizationClassification}}. For å kunne akseptere denne invitasjonen må du melde deg ut av det aktive nettverket. ",
        "REGISTRATION": ""
      }
    },
    "FAMILY_MEMBER_TO_COMPANY_MEMBER": {
      "heading": "",
      "warning": {
        "INVITATION": "Du er allerede {{constraintRoleName}} av {{constraintOrganizationName}}. Du kan ikke være {{constraintRoleName}} av et {{constraintOrganizationClassification}} og et {{invitationOrganizationClassification}} samtidig. For å kunne akseptere denne invitasjonen må du melde deg ut av det private nettverket.",
        "REGISTRATION": "Du er allerede {{constraintRoleName}} av {{constraintOrganizationName}}. Du kan ikke være {{constraintRoleName}} av et {{constraintOrganizationClassification}} og et {{invitationOrganizationClassification}} samtidig. For å kunne akseptere denne invitasjonen må du melde deg ut av det private nettverket."
      }
    },
    "FAMILY_MEMBER_TO_COOPERATIVE_MEMBER": {
      "heading": "",
      "warning": {
        "INVITATION": "Du er allerede {{constraintRoleName}} av {{constraintOrganizationName}}. Du kan ikke være {{constraintRoleName}} av et {{constraintOrganizationClassification}} og {{invitationRoleName}} i et {{invitationOrganizationClassification}} samtidig. For å kunne akseptere denne invitasjonen må du melde deg ut av det private nettverket.",
        "REGISTRATION": ""
      }
    },
    "COMPANY_ADMIN_TO_COMPANY_ADMIN": {
      "heading": "Du er allerede administrator for en annen organisasjon",
      "warning": {
        "INVITATION": "Du er allerede {{constraintRoleName}} for {{constraintOrganizationName}}. Du kan ikke være {{constraintRoleName}} for to organisasjoner på samme kundekonto.",
        "REGISTRATION": "Du er allerede {{constraintRoleName}} for {{constraintOrganizationName}}. Det er ikke mulig å være {{constraintRoleName}}  for to organisasjoner på samme kundekonto."
      }
    },
    "COMPANY_ADMIN_TO_COOPERATIVE_ADMIN": {
      "heading": "Du er allerede administrator for en annen organisasjon",
      "warning": {
        "INVITATION": "Du er allerede {{constraintRoleName}} for {{constraintOrganizationName}}. Du kan ikke være {{constraintRoleName}} for to organisasjoner på samme kundekonto.",
        "REGISTRATION": "Du er allerede {{constraintRoleName}} for {{constraintOrganizationName}}. Det er ikke mulig å være {{constraintRoleName}} for to organisasjoner på samme kundekonto."
      }
    },
    "COMPANY_MEMBER_TO_FAMILY_ADMIN": {
      "heading": "",
      "warning": {
        "INVITATION": "Du er allerede {{constraintRoleName}} av {{constraintOrganizationClassification}} {{constraintOrganizationName}}. Du kan ikke være {{constraintRoleName}} av både et {{constraintOrganizationClassification}} og et {{invitationOrganizationClassification}} samtidig. For å kunne akseptere invitasjonen må du melde deg ut av bedriftsnettverket.",
        "REGISTRATION": ""
      }
    },
    "COMPANY_MEMBER_TO_FAMILY_MEMBER": {
      "heading": "",
      "warning": {
        "INVITATION": "Du er allerede {{constraintRoleName}} av {{constraintOrganizationClassification}} {{constraintOrganizationName}}. Du kan ikke være {{constraintRoleName}} av både et {{constraintOrganizationClassification}} og et {{invitationOrganizationClassification}} samtidig. For å kunne akseptere invitasjonen må du melde deg ut av bedriftsnettverket.",
        "REGISTRATION": ""
      }
    },
    "COMPANY_MEMBER_TO_COMPANY_MEMBER": {
      "heading": "Du er allerede Medlem for en annen organisasjon",
      "warning": {
        "INVITATION": "Du er allerede {{constraintRoleName}} av {{constraintOrganizationClassification}} {{constraintOrganizationName}}. Du kan ikke være {{constraintRoleName}} av flere {{constraintOrganizationClassification}} samtidig. For å kunne akseptere denne invitasjonen må du melde deg ut av det eksisterende nettverket.",
        "REGISTRATION": "Du er allerede {{constraintRoleName}} av {{constraintOrganizationClassification}} {{constraintOrganizationName}}. Du kan ikke være {{constraintRoleName}} av flere {{constraintOrganizationClassification}} samtidig. For å kunne akseptere denne invitasjonen må du melde deg ut av det eksisterende nettverket."
      }
    },
    "COMPANY_MEMBER_TO_COOPERATIVE_MEMBER": {
      "heading": "Du er allerede Medlem for en annen organisasjon",
      "warning": {
        "INVITATION": "Du er allerede {{constraintRoleName}} av {{constraintOrganizationClassification}} {{constraintOrganizationName}}. Du kan ikke være {{constraintRoleName}} av et {{constraintOrganizationClassification}} og {{invitationRoleName}} i et {{invitationOrganizationClassification}} samtidig. For å kunne akseptere invitasjonen må du melde deg ut av bedriftsnettverket.",
        "REGISTRATION": ""
      }
    },
    "COOPERATIVE_ADMIN_TO_COMPANY_ADMIN": {
      "heading": "Du er allerede administrator for en annen organisasjon",
      "warning": {
        "INVITATION": "Du er allerede {{constraintRoleName}} for {{constraintOrganizationName}}. Du kan ikke være {{constraintRoleName}} for to organisasjoner på samme kundekonto.",
        "REGISTRATION": "Du er allerede {{constraintRoleName}} for {{constraintOrganizationName}}. Det er ikke mulig å være {{constraintRoleName}} for to organisasjoner på samme kundekonto."
      }
    },
    "COOPERATIVE_ADMIN_TO_COOPERATIVE_ADMIN": {
      "heading": "Du er allerede administrator for en annen organisasjon",
      "warning": {
        "INVITATION": "Du er allerede {{constraintRoleName}} for {{constraintOrganizationName}}. Du kan ikke være {{constraintRoleName}} for to organisasjoner på samme kundekonto.",
        "REGISTRATION": "Du er allerede {{constraintRoleName}} for {{constraintOrganizationName}}. Det er ikke mulig å være {{constraintRoleName}} for to organisasjoner på samme kundekonto."
      }
    },
    "COOPERATIVE_MEMBER_TO_FAMILY_ADMIN": {
      "heading": "",
      "warning": {
        "INVITATION": "Du er allerede {{constraintRoleName}} i {{constraintOrganizationName}}. Du kan ikke være {{constraintRoleName}} i flere {{constraintOrganizationClassification}} samtidig. For å kunne akseptere denne invitasjonen må du melde deg ut det aktive nettverket.",
        "REGISTRATION": ""
      }
    },
    "COOPERATIVE_MEMBER_TO_COOPERATIVE_MEMBER": {
      "heading": "",
      "warning": {
        "INVITATION": "Du er allerede {{constraintRoleName}} i {{constraintOrganizationName}}. Du kan ikke være {{constraintRoleName}} i flere {{constraintOrganizationClassification}} samtidig. For å kunne akseptere denne invitasjonen må du melde deg ut det aktive nettverket.",
        "REGISTRATION": ""
      }
    },
    "COOPERATIVE_MEMBER_TO_COMPANY_MEMBER": {
      "heading": "",
      "warning": {
        "INVITATION": "Du er allerede {{constraintRoleName}} i {{constraintOrganizationName}}. Du kan ikke være {{constraintRoleName}} i et {{constraintOrganizationClassification}} og {{invitationRoleName}} av et {{invitationOrganizationClassification}} samtidig. For å kunne akseptere invitasjonen må du melde deg ut av det eksisterende nettverket.",
        "REGISTRATION": "Du er allerede {{constraintRoleName}} i {{constraintOrganizationName}}. Du kan ikke være {{constraintRoleName}} i et {{constraintOrganizationClassification}} og {{invitationRoleName}} av et {{invitationOrganizationClassification}} samtidig. For å kunne akseptere invitasjonen må du melde deg ut av det eksisterende nettverket."
      }
    },
    "COOPERATIVE_MEMBER_TO_FAMILY_MEMBER": {
      "heading": "",
      "warning": {
        "INVITATION": "Du er allerede {{constraintRoleName}} i {{constraintOrganizationName}}. Du kan ikke være {{constraintRoleName}} i et borettslag og {{invitationRoleName}} av et {{invitationOrganizationClassification}} samtidig. For å kunne akseptere denne invitasjonen må du melde deg ut av det eksisterende nettverket.",
        "REGISTRATION": ""
      }
    },
    "COOPERATIVE_RESIDENT_TO_COOPERATIVE_RESIDENT": {
      "heading": "",
      "warning": {
        "INVITATION": "Du er allerede {{constraintRoleName}} hos {{constraintOrganizationName}}. Du kan ikke være {{constraintRoleName}} i to {{constraintOrganizationClassification}} samtidig. For å akseptere denne invitasjonen må du koble deg av det eksisterende borettslaget.",
        "REGISTRATION": ""
      }
    }
  },
  "roleConstraintSolution": {
    "REMOVE_RESIDENT_ROLE": {
      "prefix": "Du kan velge en annen bruker, eller ta kontakt med ",
      "link": "kundeservice",
      "suffix": "."
    },
    "REMOVE_MEMBER_ROLE": {
      "prefix": "Dette kan du gjøre i ",
      "link": "Nettverksfanen under Mine Innstillinger",
      "suffix": " (åpnes i ny fane)."
    },
    "REMOVE_FAMILY_MEMBERS": {
      "prefix": "Dette kan du gjøre ved å fjerne alle medlemmene i ",
      "link": "Nettverksfanen under Mine Innstillinger",
      "suffix": " (åpnes i ny fane)."
    },
    "REMOVE_ADMIN_ROLE": {
      "prefix": "For å administrere {{invitationOrganizationName}} kan du velge en annen bruker eller ta kontakt med ",
      "link": "kundeservice",
      "suffix": " for å bytte organisasjon for denne brukeren."
    },
    "CONTACT_SUPPORT_ABOUT_ADMIN_ROLES": {
      "prefix": "Ta kontakt med ",
      "link": "kundeservice",
      "suffix": " dersom du har spørsmål om administratorroller."
    },
    "CONTACT_SUPPORT": {
      "prefix": "Ta kontakt med ",
      "link": "kundeservice",
      "suffix": " for å få hjelp."
    }
  },
  "organizationClassification": {
    "COMPANY": "bedriftsnettverk",
    "COOPERATIVE": "borettslag",
    "FAMILY": "privat nettverk",
    "MANUFACTURER_VENDOR": "produsent/leverandør",
    "PRIVATE": "privat",
    "PUBLIC": "bedriftsnettverk",
    "SERVICE_PARTNER": "servicepartner",
    "undefined": ""
  },
  "userRole": {
    "821200000": "",
    "821200001": "Medlem",
    "821200002": "Administrator",
    "821200003": "Beboer",
    "000000001": "Organisasjon",
    "COMPANY": {
      "821200000": "",
      "821200001": "medlem",
      "821200002": "administrator",
      "821200003": "",
      "000000001": "Organisasjon"
    },
    "COOPERATIVE": {
      "821200000": "",
      "821200001": "ansatt",
      "821200002": "administrator",
      "821200003": "beboer",
      "000000001": "Organisasjon"
    },
    "FAMILY": {
      "821200000": "",
      "821200001": "medlem",
      "821200002": "administrator",
      "821200003": "",
      "000000001": "Organisasjon"
    },
    "MANUFACTURER_VENDOR": {
      "821200000": "",
      "821200001": "medlem",
      "821200002": "administrator",
      "821200003": "",
      "000000001": "Organisasjon"
    },
    "PRIVATE": {
      "821200000": "",
      "821200001": "medlem",
      "821200002": "administrator",
      "821200003": "",
      "000000001": "Organisasjon"
    },
    "PUBLIC": {
      "821200000": "",
      "821200001": "medlem",
      "821200002": "administrator",
      "821200003": "",
      "000000001": "Organisasjon"
    },
    "SERVICE_PARTNER": {
      "821200000": "",
      "821200001": "medlem",
      "821200002": "administrator",
      "821200003": "",
      "000000001": "Organisasjon"
    },
    "undefined": {
      "821200000": "",
      "821200001": "",
      "821200002": "",
      "821200003": "",
      "000000001": "Organisasjon",
      "undefined": ""
    }
  },
  "filterModal": {
    "apply": "Bruk filter",
    "clear": "Tøm filter"
  }
}
