import { EAccountNavigationVariant } from '~components/navigation/AccountNavigation';
import { EOrganizationPaymentType } from '~interfaces/Organization';
import { EUserPaymentType } from '~interfaces/User';
import { EllipsisText } from '~components/layout';
import {
    colorIllustrationLighterBlue,
    colorNeutralsBlack,
    colorPrimaryMerBlue,
    font,
    screenWidthMini,
} from '~styles/variables';
import { formatCardMask, initialsFromOrganizationName, initialsFromUser } from '~utils/formats';
import { useTranslation } from 'react-i18next';
import { useUser } from '~contexts/User';
import Avatar from '~components/visuals/Avatar';
import CheckIcon from '~assets/icons/checkmark.svg';
import IUserRole from '~interfaces/UserRoles';
import PaymentCardIcon from '~assets/icons/payment_card.svg';
import PaymentEHFIcon from '~assets/icons/payment_ehf.svg';
import PaymentEInvoicingIcon from '~assets/icons/mail.svg';
import React, { useMemo } from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';
import useOrganization from '~hooks/useOrganization';
import useOrganizationPaymentCards from '~hooks/useOrganizationPaymentCards';

export type RoleLinkProps = {
    active?: boolean;
    changeRole: () => void;
    role: IUserRole;
    variant?: EAccountNavigationVariant;
};

const USER_PAYMENT_ICONS: Record<EUserPaymentType, JSX.Element | null> = {
    [EUserPaymentType.CARD]: <PaymentCardIcon key={'card-icon'} />,
    [EUserPaymentType.EHF]: <PaymentEHFIcon key={'ehf-icon'} />,
    [EUserPaymentType.E_INVOICE]: <PaymentEInvoicingIcon key={'e_invoice-icon'} />,
    [EUserPaymentType.KLARNA]: null,
    [EUserPaymentType.POSTAL]: null,
    [EUserPaymentType.NONE]: null,
};

const ORGANIZATION_PAYMENT_ICONS: Record<EOrganizationPaymentType, JSX.Element | null> = {
    [EOrganizationPaymentType.CARD]: <PaymentCardIcon key={'card-icon'} />,
    [EOrganizationPaymentType.EHF]: <PaymentEHFIcon key={'ehf-icon'} />,
    [EOrganizationPaymentType.E_INVOICE]: <PaymentEInvoicingIcon key={'e_invoice-icon'} />,
    [EOrganizationPaymentType.POSTAL]: null,
    [EOrganizationPaymentType.NONE]: null,
};

export function UserRoleLink(props: Partial<RoleLinkProps> & Pick<RoleLinkProps, 'changeRole'>): JSX.Element | null {
    const { merUser, activePaymentMethod } = useUser();
    const { changeRole, variant, active } = props;
    const { t } = useTranslation(['common']);

    return merUser ? (
        <RoleLink
            userRoleId={undefined}
            initials={initialsFromUser(merUser)}
            title={t(`userNavigation.rolePersonal`)}
            name={`${merUser.firstName} ${merUser.lastName}`}
            payment={activePaymentMethod ?? undefined}
            paymentIcon={USER_PAYMENT_ICONS[merUser.paymentType]}
            changeRole={changeRole}
            variant={variant}
            active={active}
        />
    ) : null;
}

export function FamilyAdminRoleLink(props: RoleLinkProps): JSX.Element | null {
    const { role, ...roleLinkProps } = props;
    const { t } = useTranslation(['common']);
    const { merUser, activePaymentMethod } = useUser();
    return merUser ? (
        <RoleLink
            userRoleId={role.userRoleId}
            initials={initialsFromUser(merUser)}
            title={t(`userNavigation.roles.${role?.organizationClassification}.${role?.roleId}`, {
                network: role?.organizationName,
            })}
            name={`${merUser.firstName} ${merUser.lastName}`}
            payment={activePaymentMethod ?? undefined}
            paymentIcon={USER_PAYMENT_ICONS[merUser.paymentType]}
            {...roleLinkProps}
        />
    ) : null;
}

export function FamilyMemberRoleLink(props: RoleLinkProps): JSX.Element | null {
    const { role, ...roleLinkProps } = props;
    const { t } = useTranslation(['common']);
    const { merUser } = useUser();
    return merUser ? (
        <RoleLink
            userRoleId={role.userRoleId}
            initials={initialsFromUser(merUser)}
            title={t(`userNavigation.roles.${role?.organizationClassification}.${role?.roleId}`, {
                network: role?.organizationName,
            })}
            name={`${merUser.firstName} ${merUser.lastName}`}
            {...roleLinkProps}
        />
    ) : null;
}

export function OrganizationAdminRoleLink(props: RoleLinkProps): JSX.Element | null {
    const { role, ...roleLinkProps } = props;
    const { t } = useTranslation(['common']);
    const { organization } = useOrganization({ organizationId: role.organizationId });
    const { cards } = useOrganizationPaymentCards({
        organizationId: organization?.paymentType === EOrganizationPaymentType.CARD ? role.organizationId : undefined,
    });

    const [activePaymentMethod, paymentIcon]: [string, React.ReactNode | undefined] = useMemo(() => {
        switch (organization?.paymentType) {
            case EOrganizationPaymentType.CARD: {
                return [
                    formatCardMask(cards?.[0]?.CardMask ?? '...'),
                    ORGANIZATION_PAYMENT_ICONS[organization?.paymentType],
                ];
            }
            case EOrganizationPaymentType.E_INVOICE: {
                return [organization?.email, ORGANIZATION_PAYMENT_ICONS[organization?.paymentType]];
            }
            case EOrganizationPaymentType.EHF: {
                return [
                    organization?.ehfNumber ?? '...',
                    organization?.paymentType ? ORGANIZATION_PAYMENT_ICONS[organization?.paymentType] : undefined,
                ];
            }
            default: {
                return ['', undefined];
            }
        }
    }, [cards, organization]);

    return (
        <RoleLink
            userRoleId={role.userRoleId}
            initials={initialsFromOrganizationName(organization?.name ?? role.organizationName)}
            title={t(`userNavigation.roles.${role?.organizationClassification}.${role?.roleId}`, {
                network: organization?.name,
            })}
            name={organization?.name ?? role.organizationName}
            payment={activePaymentMethod ?? undefined}
            paymentIcon={paymentIcon}
            {...roleLinkProps}
        />
    );
}

export function OrganizationMemberResidentRoleLink(props: RoleLinkProps): JSX.Element | null {
    const { role, ...roleLinkProps } = props;
    const { t } = useTranslation(['common']);
    const { merUser } = useUser();
    return merUser ? (
        <RoleLink
            userRoleId={role.userRoleId}
            initials={initialsFromUser(merUser)}
            title={t(`userNavigation.roles.${role?.organizationClassification}.${role?.roleId}`, {
                network: role?.organizationName,
            })}
            name={`${merUser.firstName} ${merUser.lastName}`}
            {...roleLinkProps}
        />
    ) : null;
}

type LinkProps = {
    active?: boolean;
    changeRole: () => void;
    initials: string;
    name: string;
    payment?: string;
    paymentIcon?: React.ReactNode;
    title?: string;
    userRoleId?: string;
    variant?: EAccountNavigationVariant;
};

export function RoleLink(props: LinkProps): JSX.Element | null {
    const {
        title,
        name,
        payment,
        paymentIcon,
        initials,
        active,
        variant = EAccountNavigationVariant.NORMAL,
        changeRole,
    } = props;
    const { merUser } = useUser();

    return merUser ? (
        <RoleLinkContainer onClick={changeRole} className={clsx(variant, { active })}>
            <div>
                <Avatar initials={initials} active={active} />
                <p>
                    <span className={'role'}>{title}</span>
                    <span className={'name'}>{name}</span>
                </p>
                <span className={'activeIcon'}>{active ? <CheckIcon /> : null}</span>
            </div>
            {variant === EAccountNavigationVariant.NORMAL && payment ? (
                <span className={'payment'}>
                    {paymentIcon}
                    <EllipsisText>{payment}</EllipsisText>
                </span>
            ) : null}
        </RoleLinkContainer>
    ) : null;
}

const RoleLinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 0.5rem;
    cursor: pointer;
    padding: 1rem;

    &:hover {
        background-color: ${colorIllustrationLighterBlue};
    }

    & > div {
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: stretch;
        gap: 0.75rem;
    }

    & p {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        gap: 0;
        margin: 0;
        flex-grow: 1;
        min-width: 3rem;
    }

    & .activeIcon {
        min-width: 32px;
    }

    & svg {
        width: 32px;
        height: auto;
        fill: ${colorPrimaryMerBlue};
        flex-grow: 0;
        flex-shrink: 0;
    }

    & .role {
        display: block;
        font-size: ${font.size.xxs};
        line-height: ${font.lineHeight.s};
        font-family: 'SharpSans Medium', sans-serif;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: 1;
        min-width: 3rem;
    }

    & .name {
        display: block;
        font-family: 'SharpSans Semibold', sans-serif;
        line-height: ${font.lineHeight.m};
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: 1;
        min-width: 3rem;
    }

    & .payment {
        padding-left: 40px;
        flex-grow: 1;
        display: flex;
        gap: 0.25rem;
        align-items: center;
        font-size: ${font.size.xs};
        line-height: ${font.lineHeight.m};

        & svg {
            fill: ${colorNeutralsBlack};
            width: 20px;
            height: auto;
        }
    }

    &.simple {
        p {
            min-width: 0;
            display: block;
        }

        & .name {
            text-overflow: ellipsis;
            height: 1.25rem;
            overflow: hidden;
            white-space: nowrap;
            min-width: 0;
            display: block;
        }
    }

    @media screen and (max-width: ${screenWidthMini}) {
        & .payment {
            padding-left: 0;
        }
    }
`;
