import { CURRENT_TIMEZONE, DEFAULT_LOCALE, DEFAULT_TIMEZONE } from '~@constants/date';
import { Locale } from '~@types';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import IUser from '~interfaces/User';

export const formatPrice = (oldPrice: number): string => {
    const formatter = new Intl.NumberFormat(DEFAULT_LOCALE, {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    const format = formatter.format(oldPrice);
    return format;
};

export const formatLocalePriceString = (price: string): string => price.replace(',', '.');

export const formatNumber = (oldPrice: number): string => {
    const formatter = new Intl.NumberFormat(DEFAULT_LOCALE);
    const format = formatter.format(oldPrice);
    return format;
};

const LocaleCurrency: Record<Locale, string> = {
    [Locale.NORWEGIAN]: 'NOK',
    [Locale.SWEDISH]: 'SEK',
    [Locale.ENGLISH]: 'GBP',
};

// Localizes a number without fixing to decimals
export const toLocalizedNumber = (value: string | number | undefined, locale = Locale.NORWEGIAN) =>
    value !== undefined ? Number(value).toLocaleString(locale) : '';

// Show number to fixed decimals
export const toFixed = (value: string | number | undefined, locale = Locale.NORWEGIAN) =>
    value !== undefined
        ? Number(value).toLocaleString(locale, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
          })
        : '';

// If number has decimals, show them with 2 decimals. If not, show them without decimals
export const toCurrency = (value: string | number | undefined, locale = Locale.NORWEGIAN): string => {
    if (value === undefined) return '';
    const num = Number(value);
    const hasDecimals = Math.floor(num) !== num;
    return num.toLocaleString(locale, {
        style: 'currency',
        currency: LocaleCurrency[locale],
        minimumFractionDigits: hasDecimals ? 2 : 0,
        maximumFractionDigits: hasDecimals ? 2 : 0,
    });
};

// Show number with 2 decimals as currency
export const toFixedCurrency = (value: string | number | undefined, locale = Locale.NORWEGIAN) =>
    value !== undefined
        ? Number(value).toLocaleString(Locale.NORWEGIAN, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
              style: 'currency',
              currency: LocaleCurrency[locale],
          })
        : '';

export const formatCardMask = (mask: string, expiry?: string): string =>
    `${mask.replace(/X{4}/g, '**** ')}${expiry ? ' — ' + expiry.replace(/(^[0-9]{2})/g, '$1/') : ''} `;

export const msToMinutesAndHours = (ms: number): { hours: number; minutes: number } => {
    const minutes = Math.round(ms / 1000 / 60);
    const hours = Math.floor(minutes / 60);
    return {
        hours,
        minutes: minutes - hours * 60,
    };
};

export function msToMinutes(ms: number): string {
    return Math.round(ms / 1000 / 60).toString();
}

export const formatPhoneNumber = (number: string): string =>
    number.replace(/^(\+?\d{2})(\d{3})(\d{2})(\d*)/g, '$1 $2 $3 $4');

export const toDefaultTz = (date: Date): Date =>
    utcToZonedTime(zonedTimeToUtc(date, CURRENT_TIMEZONE), DEFAULT_TIMEZONE);

export const initialsFromUser = (user?: IUser): string =>
    `${user?.firstName?.substring(0, 1) ?? ''}${user?.lastName?.substring(0, 1) ?? ''}`;
export const initialsFromOrganizationName = (name?: string): string => {
    const split = name?.split(' ');
    if (split && split?.length > 1) return split[0].substring(0, 1) + split[1].substring(0, 1);
    else return split?.[0].substring(0, 1) ?? '';
};

export const formatDate = (dateString: string, locale: string = DEFAULT_LOCALE): string =>
    new Date(dateString).toLocaleString(locale, {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    });

export const formatDateShort = (dateString?: string, locale: string = DEFAULT_LOCALE): string =>
    dateString ? new Date(dateString).toLocaleDateString(locale) : '';

export const formatChargeDate = (oldDate: string) => {
    const options = { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' } as const;
    const formattedDate = new Date(oldDate);
    return formattedDate.toLocaleDateString('nb-NO', options).replace(/[,.]/g, '');
};

export const formatRfidUid = (name: string) => name.replace(/(^.{6}).*/, '$1…');

export const formatOrganizationNumber = (organizationNumber: string | undefined) => {
    return organizationNumber
        ? organizationNumber
              .replace(/[^0-9]/g, '')
              .match(/.{1,3}/g)
              ?.join(' ')
              .substr(0, 11) || ''
        : organizationNumber;
};
