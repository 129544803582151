import { EOrganizationClassification } from '~interfaces/Organization';
import { ERoleId } from '~interfaces/Invitation';
import { NavLink } from 'react-router-dom';
import {
    colorNeutralsBlack,
    colorNeutralsWhite,
    colorOnWhiteLightGrey,
    colorOnWhiteLighterGrey,
    colorPrimaryMerBlue,
    font,
    screenWidthMedium,
    screenWidthMini,
    screenWidthSmall,
} from '~styles/variables';
import { initialsFromOrganizationName, initialsFromUser } from '~utils/formats';
import { useTranslation } from 'react-i18next';
import { useUser } from '~contexts/User';
import AccountNavigation from '~components/navigation/AccountNavigation';
import Avatar from '~components/visuals/Avatar';
import CloseIcon from '~assets/icons/close.svg';
import IUserRole from '~interfaces/UserRoles';
import MenuIcon from '~assets/icons/menu.svg';
import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import UserIcon from '~assets/icons/user.svg';
import clsx from 'clsx';
import styled from '@emotion/styled';
import useOrganization from '~hooks/useOrganization';

type UserProfileProps = {
    showUserMenu?: boolean | undefined;
};

export default function UserProfile(props: UserProfileProps): JSX.Element | null {
    const { showUserMenu } = props;
    const { merUser, isRegistered, personalRole, alternateRole, rolesCount, isRoleActive } = useUser();
    const [userNavigationIsOpen, setUserNavigationIsOpen] = useState<boolean>(false);
    const domRef = useRef<HTMLDivElement>();

    function toggleUserNavigation(): void {
        setUserNavigationIsOpen(!userNavigationIsOpen);
    }

    useEffect(() => {
        function handler(e: MouseEvent) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (!domRef.current?.contains(e.target)) setUserNavigationIsOpen(false);
        }

        document.addEventListener('mousedown', handler);
        return () => {
            document.removeEventListener('mousedown', handler);
        };
    }, []);

    const getRoleComponent = useCallback(
        (role: IUserRole) => {
            const active = isRoleActive(role);
            switch (role?.organizationClassification) {
                case EOrganizationClassification.FAMILY: {
                    return role.roleId === ERoleId.ADMINISTRATOR ? (
                        <CurrentRoleFamilyAdmin active={active} role={role} />
                    ) : (
                        <CurrentRoleFamilyMember active={active} role={role} />
                    );
                }
                case EOrganizationClassification.COOPERATIVE:
                case EOrganizationClassification.COMPANY:
                case EOrganizationClassification.PUBLIC: {
                    switch (role.roleId) {
                        case ERoleId.SMB: {
                            return <CurrentRoleOrganizationSmb active={active} role={role} />;
                        }
                        case ERoleId.ADMINISTRATOR: {
                            return <CurrentRoleOrganizationAdmin active={active} role={role} />;
                        }
                        default: {
                            return <CurrentRoleOrganizationMemberResident active={active} role={role} />;
                        }
                    }
                }
                default: {
                    return <RoleCardUser active={active} role={role} />;
                }
            }
        },
        [isRoleActive],
    );

    const personalRoleComponent = useMemo(() => {
        return personalRole ? getRoleComponent(personalRole) : null;
    }, [getRoleComponent, personalRole]);

    const alternateRoleComponent = useMemo(() => {
        return alternateRole ? getRoleComponent(alternateRole) : null;
    }, [alternateRole, getRoleComponent]);

    if (!isRegistered || showUserMenu === false) return null;

    return (
        <ProfileArea ref={domRef as unknown as MutableRefObject<HTMLDivElement>} id={'profileArea'}>
            {alternateRoleComponent ? (
                <>
                    {alternateRoleComponent}
                    <span className={'divider'} />
                </>
            ) : null}
            {personalRoleComponent ? (
                <>
                    {personalRoleComponent}
                    <span className={'divider'} />
                </>
            ) : null}
            {rolesCount > 2 ? (
                <>
                    <RoleSelectNavLink to={'/roles'}>
                        <UserIcon />
                        <span className={'roleCount'}>{rolesCount}</span>
                    </RoleSelectNavLink>
                    <span className={'divider'} />
                </>
            ) : null}
            <ToggleNavigation
                type={'button'}
                onClick={toggleUserNavigation}
                className={userNavigationIsOpen ? 'menu-is-open' : undefined}
            >
                {userNavigationIsOpen ? <CloseIcon /> : <MenuIcon />}
            </ToggleNavigation>
            {userNavigationIsOpen && (
                <AccountNavigation
                    userName={merUser?.firstName + ' ' + merUser?.lastName}
                    userEmail={merUser?.email}
                    toggleUserNavigation={toggleUserNavigation}
                />
            )}
        </ProfileArea>
    );
}

type RoleCardProps = {
    active?: boolean;
    initials: string;
    name: string;
    role: IUserRole;
    showRoleTitle?: boolean;
};

function RoleCard(props: RoleCardProps): JSX.Element {
    const { changeRole } = useUser();
    const { role, name, initials, active } = props;
    const { t } = useTranslation(['common']);

    const { organizationClassification, roleId, organizationName } = role ?? {};

    const roleName =
        (organizationClassification === EOrganizationClassification.PRIVATE ||
            organizationClassification === undefined) &&
        roleId === ERoleId.USER
            ? t(`userNavigation.rolePersonal`)
            : organizationClassification && roleId
              ? t(`userNavigation.roles.${organizationClassification}.${roleId}`, {
                    network: organizationName,
                })
              : undefined;


    return (
        <RoleCardContainer onClick={() => changeRole(role)} className={clsx({ active })}>
            <RoleCardRoleNameContainer>
                {role ? <div className="role">{roleName}</div> : null}
                <div className="name">{name}</div>
            </RoleCardRoleNameContainer>
            <Avatar initials={initials} active={active} />
        </RoleCardContainer>
    );
}

type RoleCardVariantProps = {
    active?: boolean;
    role: IUserRole;
    showRoleTitle?: boolean;
};

function RoleCardUser(props: RoleCardVariantProps): JSX.Element {
    const { role, ...remainingProps } = props;
    const { merUser } = useUser();

    return (
        <RoleCard
            name={`${merUser?.firstName} ${merUser?.lastName}`}
            initials={initialsFromUser(merUser ?? undefined)}
            role={role}
            {...remainingProps}
        />
    );
}

function CurrentRoleFamilyAdmin(props: RoleCardVariantProps): JSX.Element {
    const { role, ...remainingProps } = props;
    const { merUser } = useUser();
    return (
        <RoleCard
            name={`${merUser?.firstName} ${merUser?.lastName}`}
            role={role}
            initials={initialsFromUser(merUser ?? undefined)}
            {...remainingProps}
        />
    );
}

function CurrentRoleFamilyMember(props: RoleCardVariantProps): JSX.Element {
    const { role, ...remainingProps } = props;
    const { merUser } = useUser();
    return (
        <RoleCard
            name={`${merUser?.firstName} ${merUser?.lastName}`}
            role={role}
            initials={initialsFromUser(merUser ?? undefined)}
            {...remainingProps}
        />
    );
}

function CurrentRoleOrganizationSmb(props: RoleCardVariantProps): JSX.Element {
    const { role, ...remainingProps } = props;
    const { organization } = useOrganization({ organizationId: role?.organizationId });
    return (
        <RoleCard
            name={organization?.name ?? ''}
            role={role}
            initials={initialsFromOrganizationName(organization?.name)}
            {...remainingProps}
        />
    );
}

function CurrentRoleOrganizationAdmin(props: RoleCardVariantProps): JSX.Element {
    const { role, ...remainingProps } = props;
    return (
        <RoleCard
            name={role?.organizationName ?? ''}
            role={role}
            initials={initialsFromOrganizationName(role?.organizationName)}
            {...remainingProps}
        />
    );
}

function CurrentRoleOrganizationMemberResident(props: RoleCardVariantProps): JSX.Element {
    const { role, ...remainingProps } = props;
    const { merUser } = useUser();
    return (
        <RoleCard
            name={`${merUser?.firstName} ${merUser?.lastName}`}
            role={role}
            initials={initialsFromUser(merUser ?? undefined)}
            {...remainingProps}
        />
    );
}

const ProfileArea = styled.div`
    position: fixed;
    margin-left: auto;
    padding-right: 1rem;
    top: 0;
    right: 0;
    grid-area: headerNav;
    z-index: 9001;
    height: 64px;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    @media screen and (max-width: ${screenWidthMedium}) {
        display: none;
        visibility: hidden;
    }

    & .divider {
        width: 1px;
        height: 1rem;
        background-color: ${colorOnWhiteLightGrey};
    }
`;

const RoleCardContainer = styled.div`
    display: flex;
    padding: 0.5rem 1.5rem;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    height: 64px;
    border: none;
    -webkit-appearance: none;
    font-family: 'SharpSans Medium', sans-serif;
    background-color: ${colorNeutralsWhite};
    font-size: ${font.size.s};
    position: relative;
    gap: 1rem;
    max-width: 20rem;

    @media screen and (min-width: ${screenWidthMini}) {
        font-size: ${font.size.m};
    }

    @media screen and (max-width: ${screenWidthSmall}) {
        display: none;
    }

    &.active {
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background-color: ${colorPrimaryMerBlue};
        }
    }

    &:not(.active):hover {
        background-color: ${colorOnWhiteLighterGrey};

        span {
            text-decoration: underline;
        }
    }

    @media screen and (max-width: ${screenWidthMedium}) {
        .avatar {
            height: 32px;
            width: 32px;
            line-height: 32px;
        }
    }
`;

const RoleCardRoleNameContainer = styled.div`
    visibility: visible;
    font-family: 'SharpSans Bold', sans-serif;
    font-size: ${font.size.m};
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;
    flex: 1;
    min-width: 10rem;

    & .role {
        display: block;
        font-family: 'SharpSans Medium', sans-serif;
        font-size: ${font.size.xxs};
        text-align: right;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        min-width: 2rem;
    }

    & .name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: 1;
        min-width: 2rem;
        text-align: right;
    }
`;

const RoleSelectNavLink = styled(NavLink)`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.75rem;

    & svg {
        width: 40px;
        height: 40px;
        fill: ${colorNeutralsBlack};

        & path {
            transform: scale(1.5);
        }
    }

    & .roleCount {
        position: absolute;
        top: 50%;
        left: 50%;
        border-radius: 50%;
        background-color: ${colorPrimaryMerBlue};
        color: ${colorNeutralsWhite};
        font-size: ${font.size.xxs};
        border: 2px solid ${colorNeutralsWhite};
        text-align: center;
        height: 1.25rem;
        width: 1.25rem;
        line-height: 1.075rem;
    }
`;

const ToggleNavigation = styled.button`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    -webkit-appearance: none;
    font-family: 'SharpSans Medium', sans-serif;
    background-color: ${colorNeutralsWhite};
    font-size: ${font.size.s};
    cursor: pointer;
    padding: 0.75rem;

    & svg {
        width: 40px;
        height: 40px;
        fill: ${colorNeutralsBlack};
    }

    @media screen and (min-width: ${screenWidthMini}) {
        font-size: ${font.size.m};
    }

    @media screen and (max-width: ${screenWidthSmall}) {
        display: none;
    }
`;
