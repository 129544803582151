import { Actions, HorizontalDivider, SectionCentered } from '~components/layout';
import { ButtonVariant } from '~@types';
import { EOrganizationClassification } from '~interfaces/Organization';
import { POLLING_CONFIG_MEDIUM } from '~@constants/polling';
import { useApiPolling } from '~contexts/Api';
import { useTranslation } from 'react-i18next';
import { useUser } from '~contexts/User';
import ApiError from '~classes/ApiError';
import Button from '~components/clickables/Button';
import FormError from '~components/forms/FormError';
import FullPageWorkingIndicator from '~components/indicators/FullPageWorkingIndicator';
import IInvitation, { EInvitationType, ERoleId } from '~interfaces/Invitation';
import IUserRole, { ERoleConstraint, ERoleConstraintOrigin } from '~interfaces/UserRoles';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import RoleConstraintSolution from '~components/roles/RoleConstraintSolution';
import RoleConstraintWarning, { RoleConstraintTranslationProperties } from '~components/roles/RoleConstraintWarning';
import styled from '@emotion/styled';
import useInvitation from '~hooks/useInvitation';
import useInvitations from '~hooks/useInvitations';
import useOrganization from '~hooks/useOrganization';
import useRoles, { RoleConstraint } from '~hooks/useRoles';

export type OrganizationBecomeMemberProps = {
    onCancel?: () => void;
    onSuccess?: () => void;
    organizationId?: string;
};

export default function OrganizationBecomeMember(props: OrganizationBecomeMemberProps): JSX.Element {
    const { organizationId, onSuccess, onCancel } = props;
    const { t } = useTranslation(['organization', 'common']);
    const { poll } = useApiPolling();
    const { merUser } = useUser();
    const { hasRoleConstraint, loading: rolesLoading, mutateRoles } = useRoles();
    const { organization } = useOrganization({ organizationId });
    const { sendInvitations } = useInvitations({ organizationId });
    const { accept } = useInvitation({});
    const [error, setError] = useState<ApiError | undefined>();
    const [roleConstraint, setRoleConstraint] = useState<RoleConstraint | undefined | null>(undefined);
    const [processing, setProcessing] = useState<boolean>(false);
    const [checkingConstraint, setCheckingConstraint] = useState<boolean>(false);

    const checkRoleConstraint = useCallback(async () => {
        setCheckingConstraint(true);
        await mutateRoles();
        const roleConstraint =
            hasRoleConstraint(ERoleId.MEMBER, EOrganizationClassification.COMPANY) ??
            hasRoleConstraint(ERoleId.MEMBER, EOrganizationClassification.COOPERATIVE) ??
            hasRoleConstraint(ERoleId.MEMBER, EOrganizationClassification.PUBLIC);
        setRoleConstraint(roleConstraint ?? null);
        setCheckingConstraint(false);
    }, [hasRoleConstraint, mutateRoles]);

    // we're going to send an invitation, keep the id of the sent invitation then accept it
    const sendAndAcceptInvitation = useCallback(async () => {
        if (!organization || !merUser) return;
        const invitation = {
            inviteeEmail: merUser.email,
            inviteeName: merUser.email,
            roleId: ERoleId.MEMBER,
            organizationClassification: organization.organizationClassification,
            invitationType: EInvitationType.ACCOUNT_MIGRATION,
        };
        try {
            const invitations = await sendInvitations([invitation]);
            const invitationId = invitations[0].id;
            await poll<IInvitation[]>(
                `/organizations/${organizationId}/invitations`,
                {},
                (_error, invitations) => {
                    return !!invitations?.find((invitation) => invitation.id === invitationId);
                },
                POLLING_CONFIG_MEDIUM.maxAttempts,
                POLLING_CONFIG_MEDIUM.delay,
            );
            await accept(invitationId);
            await poll<IUserRole[]>(
                `/users/me/roles`,
                {},
                (_error, roles) => {
                    return !!roles?.find(
                        (role) => role.organizationId === organizationId && role.roleId === ERoleId.MEMBER,
                    );
                },
                POLLING_CONFIG_MEDIUM.maxAttempts,
                POLLING_CONFIG_MEDIUM.delay,
            );
            onSuccess?.();
        } catch (e) {
            setError(e as ApiError);
            console.error(e);
        }
    }, [accept, merUser, organization, organizationId, poll, sendInvitations, onSuccess]);

    const hasCheckedRoleConstraint = roleConstraint !== undefined && !checkingConstraint;
    const shouldCheckRoleConstraint = !rolesLoading && !hasCheckedRoleConstraint;
    const shouldProcessMembership =
        !processing &&
        !!organizationId &&
        !!organization &&
        !!merUser &&
        !checkingConstraint &&
        hasCheckedRoleConstraint &&
        !roleConstraint;

    useEffect(() => {
        if (shouldProcessMembership) {
            setProcessing(true);
            void sendAndAcceptInvitation();
        }
    }, [sendAndAcceptInvitation, shouldProcessMembership]);

    useEffect(() => {
        if (shouldCheckRoleConstraint) void checkRoleConstraint();
    }, [checkRoleConstraint, shouldCheckRoleConstraint]);

    const constraintErrorMessage = useMemo(() => {
        const errorCode = error?.payload?.errorCode;
        if (errorCode && Object.entries(ERoleConstraint).some(([, value]) => value.toString() === errorCode))
            // @ts-expect-error errorCode will pick up a translation but i18n types is having issues with this use
            return t(`common:roleConstraint.${errorCode}.warning.REGISTRATION`);
    }, [error, t]);

    const constraintTranslationProperties = {
        constraintRoleName:
            roleConstraint?.role?.organizationClassification && roleConstraint?.role?.roleId
                ? t(
                      `common:userRole.${roleConstraint?.role?.organizationClassification}.${roleConstraint?.role?.roleId}`,
                  )
                : '',
        constraintOrganizationClassification: t(
            `common:organizationClassification.${roleConstraint?.role?.organizationClassification}`,
        ),
        constraintOrganizationName: roleConstraint?.role?.organizationName ?? '',
    };

    const tryAgain = useCallback(() => {
        setProcessing(false);
        setCheckingConstraint(false);
        setRoleConstraint(undefined);
        setError(undefined);
    }, []);

    const isWaiting = rolesLoading || checkingConstraint;
    const hasError = error || roleConstraint || constraintErrorMessage;

    return isWaiting ? (
        <FullPageWorkingIndicator />
    ) : hasError ? (
        <Container>
            {roleConstraint ? (
                <>
                    <RoleConstraintWarning
                        constraint={roleConstraint.constraint}
                        roleConstraintOrigin={ERoleConstraintOrigin.REGISTRATION}
                        translationProperties={constraintTranslationProperties as RoleConstraintTranslationProperties}
                    />
                    {roleConstraint.constraint.solutions?.map((s) => <RoleConstraintSolution solution={s} key={s} />)}
                </>
            ) : null}
            {error && constraintErrorMessage ? (
                <FormError error={error} translationOverride={constraintErrorMessage as string} />
            ) : error ? (
                <FormError error={error} />
            ) : null}
            <HorizontalDivider />
            <Actions right={true}>
                <Button variant={ButtonVariant.TEXT} isSubmitting={checkingConstraint} onClick={tryAgain}>
                    {t('organization:register.step.becomeMember.tryAgain')}
                </Button>
                <Button onClick={onCancel}>{t('organization:register.step.becomeMember.errorContinue')}</Button>
            </Actions>
        </Container>
    ) : (
        <FullPageWorkingIndicator
            scrollText={t('organization:register.step.becomeMember.loadingText', { returnObjects: true })}
        />
    );
}

const Container = styled(SectionCentered)`
    max-width: 768px;
    margin-bottom: 0;

    > * {
        max-width: 720px;
    }
`;
