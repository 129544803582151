import { NavLink, useLocation } from 'react-router-dom';
import {
    colorIllustrationLighterBlue,
    colorNeutralsWhite,
    colorOnWhiteDarkGrey,
    colorPrimaryMerBlue,
    colorPrimaryMerTurquoise,
    font,
    screenWidthMedium,
    transitionSnappy,
} from '~styles/variables';
import ArrowDown from '~assets/icons/arrow_down.svg';
import ArrowUp from '~assets/icons/arrow_up.svg';
import React from 'react';
import styled from '@emotion/styled';

export interface INavigationGroupLink {
    title: string;
    to: string;
}

export interface INavigationGroup {
    icon: JSX.Element;
    links: INavigationGroupLink[];
    title: string;
}

export type NavigationGroupProps = INavigationGroup & {
    expanded?: boolean;
    onClick: () => void;
};

export default function NavigationGroup(props: NavigationGroupProps): JSX.Element {
    const { title, icon, expanded, onClick, links } = props;
    const location = useLocation();

    const active = !!links.find((l) => l.to === location.pathname);

    return (
        <NavigationGroupContainer>
            <NavigationGroupHeading onClick={onClick} className={active ? 'active' : undefined}>
                <span className={'icon'}>{icon}</span>
                <span className={'title'}>{title}</span>
                {/*<span className={'icon'}>{expanded ? <ArrowUp /> : <ArrowDown />}</span>*/}
            </NavigationGroupHeading>
            {expanded ? (
                <NavigationGroupLinks>
                    {links.map((link, index) => (
                        <NavigationGroupLink key={index} to={link.to}>
                            {link.title}
                        </NavigationGroupLink>
                    ))}
                </NavigationGroupLinks>
            ) : null}
        </NavigationGroupContainer>
    );
}

const NavigationGroupContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
`;

const NavigationGroupHeading = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    gap: 1rem;

    padding: 1.25rem 1rem;
    cursor: pointer;
    position: relative;

    & .expand {
        flex: 0;
    }

    & .icon {
        flex: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & svg {
            height: 24px;
            width: 24px;
            fill: currentColor;
            transition: all ${transitionSnappy};
        }
    }

    & .title {
        flex: 1;
        font-size: ${font.size.m};
        line-height: ${font.lineHeight.m};
        font-family: 'SharpSans Semibold', sans-serif;
    }

    &:hover {
        color: ${colorPrimaryMerBlue};
        background: ${colorNeutralsWhite};
    }

    &:focus {
        outline: none;
    }

    &.active {
        color: ${colorPrimaryMerBlue};

        &:before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 4px;
            height: 100%;
            background-color: ${colorPrimaryMerTurquoise};
        }
    }

    @media screen and (max-width: ${screenWidthMedium}) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;

        &.active:before {
            left: auto;
            right: 0;
        }
    }
`;

const NavigationGroupLinks = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
`;

const NavigationGroupLink = styled(NavLink)`
    padding: 0.5rem 1rem 0.5rem 3.5rem;
    font-size: ${font.size.s};
    font-weight: ${font.weight.regular};
    line-height: ${font.lineHeight.l};
    text-decoration: none;
    color: ${colorOnWhiteDarkGrey};
    flex: 1;
    width: 100%;
    outline: none;

    &:focus {
        outline: none;
    }

    &:hover {
        color: ${colorPrimaryMerBlue};
        background: ${colorNeutralsWhite};
    }

    &.active {
        font-weight: ${font.weight.semiBold};
        color: ${colorPrimaryMerBlue};
        background-color: ${colorIllustrationLighterBlue};
        &:hover {
            color: ${colorPrimaryMerBlue};
            background-color: ${colorIllustrationLighterBlue};
        }
    }

    @media screen and (max-width: ${screenWidthMedium}) {
        padding: 0.5rem 1rem 0.5rem 4rem;
    }
`;
