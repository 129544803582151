import { Error } from '~components/forms/Error';
import { colorNeutralsBlack, colorPrimaryMerBlue, font } from '~styles/variables';
import CheckmarkIcon from '~assets/icons/checkmark.svg';
import FormErrorIcon from '~assets/icons/form_error.svg';
import React from 'react';
import styled from '@emotion/styled';

const Container = styled(Error)<{ margin?: string }>`
    color: ${colorNeutralsBlack};
    border: 1px solid ${colorPrimaryMerBlue};
    margin: ${(props) => (props.margin ? props.margin : '0')};
    font-size: ${font.size.m};
    line-height: ${font.lineHeight.m};

    &:before {
        background-color: ${colorPrimaryMerBlue};
    }
`;

export enum EAlertVariant {
    ALERT,
    SUCCESS,
}

type AlertProps = {
    children: React.ReactNode;
    margin?: string;
    variant?: EAlertVariant;
};

export default function AlertComponent(props: AlertProps): JSX.Element {
    const { children, variant = EAlertVariant.ALERT, margin } = props;
    return (
        <Container margin={margin}>
            <span className={'icon'}>
                {variant === EAlertVariant.ALERT ? <FormErrorIcon /> : null}
                {variant === EAlertVariant.SUCCESS ? <CheckmarkIcon /> : null}
            </span>
            {children}
        </Container>
    );
}
