import { EOrganizationClassification } from '~interfaces/Organization';
import { ETrackingEvent } from '~@types/tracking';
import ApiError from '~classes/ApiError';
import IInvitation, { EInvitationType, ERoleId } from '~interfaces/Invitation';
import useAnalytics from '~hooks/useAnalytics';
import useApi from '~contexts/Api';
import useSWR from 'swr';

const noOrganizationError = new Error('Active user has no organization');
const BASE_API_PATH = '/organizations';

export type OrganizationInvite = {
    invitationType?: EInvitationType;
    inviteeEmail: string;
    inviteeName?: string;
    organizationClassification: EOrganizationClassification;
    roleId: ERoleId;
};

export type OrganizationInvitesPayload = OrganizationInvite[];

export type UseInvitationsResponse = {
    error?: ApiError;
    invitations: IInvitation[];
    loading: boolean;
    revokeInvitation: (invitationId: string) => Promise<void>;
    sendInvitations: (invitations: OrganizationInvite[], organizationId?: string) => Promise<IInvitation[]>;
};

type UseInvitationsProps = {
    organizationId?: string;
};

export default function useInvitations(props: UseInvitationsProps): UseInvitationsResponse {
    const { organizationId } = props || {};
    const { get, post, del } = useApi();
    const { trackGA4Event } = useAnalytics();

    const {
        data: invitations = [],
        error: error,
        mutate: mutateInvitations,
        isValidating: loading,
    } = useSWR<IInvitation[] | void, ApiError>(
        organizationId ? `${BASE_API_PATH}/${organizationId}/invitations` : null,
        get,
        {
            revalidateOnFocus: false,
            shouldRetryOnError: false,
        },
    );

    // Tim: We allow overriding the organizationId as there are some cases where we need to create an organization
    // before sending invitations, and it's tricky to wait for this hook to receive the new organizationId
    // before being able to send. Ideally in v2 we will have an endpoint that creates these family organizations on first
    // creation of invitation
    const sendInvitations: UseInvitationsResponse['sendInvitations'] = async (invites, id = organizationId) => {
        if (!id) throw noOrganizationError;
        const newInvitations = await post<IInvitation[], OrganizationInvitesPayload>(
            `${BASE_API_PATH}/${id}/invitations`,
            invites,
        );
        await mutateInvitations([...invitations, ...newInvitations]);
        invites.forEach((invite) => trackGA4Event(ETrackingEvent.INVITATION_SEND, { value: invite.roleId }));
        return newInvitations;
    };

    const revokeInvitation: UseInvitationsResponse['revokeInvitation'] = async (invitationId) => {
        if (!organizationId) throw noOrganizationError;
        await del(`${BASE_API_PATH}/${organizationId}/invitations/${invitationId}`);
        await mutateInvitations([...invitations.filter((invite) => invite.id !== invitationId)]);
        trackGA4Event(ETrackingEvent.INVITATION_REVOKE);
    };

    return {
        invitations,
        sendInvitations,
        revokeInvitation,
        loading,
        error,
    };
}
